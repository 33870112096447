import React from 'react';
import "./home_logos.less";

import imgPaulMitchell from "../../../assets/images/home/logos/logo-paul-mitchell.svg";
import imgPulpRiot from "../../../assets/images/home/logos/logo-pulpriot.svg";
import imgSalonCentric from "../../../assets/images/home/logos/logo-salon-centric.svg";
import imgPureology from "../../../assets/images/home/logos/logo-pureology.svg";
import imgAvedaInstitutes from "../../../assets/images/home/logos/logo-aveda-institutes.svg";
import imgLorealLuxe from "../../../assets/images/home/logos/logo-loreal-luxe.svg";
import imgMizani from "../../../assets/images/home/logos/logo-mizani.svg";
import imgOgle from "../../../assets/images/home/logos/logo-ogle-school.svg";
import imgPaulMitchellSchools from "../../../assets/images/home/logos/logo-paul-mitchell-schools.svg";
import imgSalonProAcademy from "../../../assets/images/home/logos/logo-salon-pro-academy.svg";
import imgPba from "../../../assets/images/home/logos/logo-pba.png";
import imgUlta from "../../../assets/images/home/logos/logo-ulta.svg";

export class HomeLogos extends React.Component<{}, {}> {
  private set01: React.RefObject<HTMLDivElement>;
  private set02: React.RefObject<HTMLDivElement>;
  private set03: React.RefObject<HTMLDivElement>;
  private sets: React.RefObject<HTMLDivElement>[] = [];
  private activeSet: number = 0;
  private fadeInterval: any = null;

  constructor(props: any) {
    super(props);
    this.set01 = React.createRef();
    this.set02 = React.createRef();
    this.set03 = React.createRef();
    this.sets.push(this.set01);
    this.sets.push(this.set02);
    this.sets.push(this.set03);
  }

  componentDidMount() {
    this.fadeInterval = setInterval(() => {
      if (this.activeSet > 2) {
        this.activeSet = 0;
      }
      this.sets.forEach(set => {
        if (set.current) {
          set.current.style.opacity = '0';
        }
      });
      const selectedSet = this.sets[this.activeSet];
      if (selectedSet && selectedSet.current) {
        selectedSet.current.style.opacity = '1';
      }
      this.activeSet++;
    }, 3000);
  }

  componentWillUnmount() {
    clearTimeout(this.fadeInterval);
    this.fadeInterval = null;
  }

  render(): React.ReactNode {
    return <div className="cm-home-logos cm-home__section">
      <div ref={ this.set01 } className="cm-home-logos__set">
        <img src={ imgPaulMitchell } alt="Paul Mitchell" />
        <img src={ imgPulpRiot } alt="Pulp Riot" />
        <img src={ imgSalonCentric } alt="SalonCentric" />
        <img src={ imgPureology } alt="Pureology" />
      </div>
      <div ref={ this.set02 } className="cm-home-logos__set" style={{ opacity: 0 }}>
        <img src={ imgAvedaInstitutes } alt="Aveda Institutes" />
        <img src={ imgLorealLuxe } alt="Loreal" />
        <img src={ imgMizani } alt="Mizani" />
        <img src={ imgOgle } alt="Ogle" />
      </div>
      <div ref={ this.set03 } className="cm-home-logos__set" style={{ opacity: 0 }}>
        <img src={ imgPaulMitchellSchools } alt="Paul Mitchell Schools" />
        <img src={ imgSalonProAcademy } alt="Salon Pro Academy" />
        <img src={ imgPba } alt="PBA" />
        <img src={ imgUlta } alt="Ulta" />
      </div>
    </div>;
  }
}
