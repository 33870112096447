import React from 'react';
import "./home_overcome.less";

import iconTrophy from "../../../assets/images/home/icons/icons_trophy.svg";
import iconHumanize from "../../../assets/images/home/icons/icons_humanize.svg";
import iconReduce from "../../../assets/images/home/icons/icons_reduce.svg";
import iconNetwork from "../../../assets/images/home/icons/icons_network.svg";
import iconTarget from "../../../assets/images/home/icons/icons_target.svg";
import iconVoice from "../../../assets/images/home/icons/icons_voice.svg";

export class HomeOvercome extends React.Component<{}, {}> {
  render() {
    return <div className="cm-home-overcome cm-home__section cm-flex-center cm-flex-column">
      <h2 className="cm-h-italics">
        So much more than a job board.
      </h2>
      <h2 className="cm-gutter-bottom-xxl">
        Canvas ME empowers you to overcome your biggest hiring challenges in the beauty industry.
      </h2>
      <div className="cm-home__info-blocks cm-flex-responsive-row">
        <div className="cm-flex-column">
          <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
            <img className="cm-home__icon" src={ iconTrophy } alt="trophy" />
            <p>Empowers your salon, spa or barbershop to recruit top talent</p>
          </div>
          <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
            <img className="cm-home__icon" src={ iconHumanize } alt="humanize" />
            <p>Humanizes your brand and culture through video and imagery</p>
          </div>
          <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
            <img className="cm-home__icon" src={ iconReduce } alt="reduce" />
            <p>Reduces employee turnover</p>
          </div>
        </div>
        <div className="cm-flex-column">
          <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
            <img className="cm-home__icon" src={ iconNetwork } alt="network" />
            <p>Unites professionals to network and collaborate from anywhere</p>
          </div>
          <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
            <img className="cm-home__icon" src={ iconTarget } alt="target" />
            <p>Creates online portfolios that humanize &amp; land the RIGHT career opportunity</p>
          </div>
          <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
            <img className="cm-home__icon" src={ iconVoice } alt="voice" />
            <p>Gives beauty students and professionals a voice</p>
          </div>
        </div>
      </div>
    </div>;
  }
}
