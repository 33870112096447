import React, { useEffect } from "react";
import { getStartedViaSource, getStaticAssetPath } from "../../../utils/utils";
import { Button } from "antd";
import { global_router } from "../../../routers";
import { router_get_started } from "../../../utils/enum";
import { Accounts, LiveUserCount } from "../../../utils/constants";


interface Props {
  name: string;
  location?: string;
  image: string;
  type: string;
  url: string;
  visualization: 'canvas' | 'opportunity'
}

const CanvasTile: React.FC<Props> = (props) => {
  const { image, name, location, type, url, visualization = 'canvas' } = props;
  const openPortfolio = () => {
    window.location.href = url;
  }
  const displayName = visualization === 'canvas' ? name : name.toUpperCase();
  const actionLabel = visualization === 'canvas' ? 'Launch Portfolio' : 'View Opportunity';
  
  return (
    <div className="cm-sp-canvas-tile cm-flex-column" onClick={() => openPortfolio()}>
      <div className="cm-flex-1">
        <div className="cm-sp-canvas-tile-img" style={{ backgroundImage: `url(${image})` }} />
      </div>
      <div className="cm-sp-canvas-tile__footer">
        <h4>{displayName}</h4>
        {visualization === 'canvas' && <h5>{location}</h5>}
      </div>
      <div className="cm-sp-canvas-tile-overlay">{actionLabel}</div>
      {visualization === 'canvas' && <div className="cm-sp-canvas-tile-type">{type}</div>}
      {visualization === 'opportunity' && <div className="cm-sp-canvas-tile-type--opportunity">{type}</div>}
      <img className="cm-sp-canvas-ribbon" src={getStaticAssetPath(`studentpro-landing/symbol-with-background.png`)} alt="Canvas ME symbol" />
    </div>
  )
};

const FeaturedPortfolios: React.FC = () => {
  const [userCount, setUserCount] = React.useState(Accounts.USERS)

  const fetchAndSetUserCount = async () => {
    const countString = await LiveUserCount();
    setUserCount(countString);
  }

  useEffect(() => {
    fetchAndSetUserCount();
  }, [])

  const portfolios = [
    {
      name: "Lauren",
      location: "West Jordan, UT",
      image: "lauren.png",
      type: "cosmo",
      url: "https://www.canvasme.com/canvas/lauren-jones"
    },
    {
      name: "Telaysia",
      location: "Tampa, FL",
      image: "telaysia.png",
      type: "esthi",
      url: "https://www.canvasme.com/canvas/telaysia-allen"
    },
    {
      name: "Erick",
      location: "Boston, MA",
      image: "erick.png",
      type: "makeup",
      url: "https://www.canvasme.com/canvas/erick-vargas"
    },
    {
      name: "Rashleigh",
      location: "Arlington, TX",
      image: "rashleigh.png",
      type: "barber",
      url: "https://www.canvasme.com/canvas/rashleigh-beaton"
    },
  ];

  return (
    <div>
      <div className="cm-sp-headline">
        <img src={getStaticAssetPath('studentpro-landing/canvas-symbol.png')} alt="Canvas symbol" />
        <div>
          <h1>Featured Canvas Portfolios.</h1>
          <p>Join a network of {userCount} beauty, barber, esthi, nails, makeup, students, pros, schools, brands and businesses looking to find their people.
          </p>
        </div>
      </div>
      <div className="cm-flex-row cm-flex-wrap cm-flex-space">
        { portfolios.map(p => <CanvasTile visualization="canvas" name={p.name} location={p.location} image={getStaticAssetPath(`studentpro-landing/portfolios/${p.image}`)} type={p.type} url={p.url} /> )}
      </div>
      <div className="cm-flex-row cm-flex-center cm-v-spacing">
        <Button className="btn-primary" onClick={() =>  global_router.history.push(`${router_get_started}`)}>Create Your Free Account</Button>
      </div>
    </div>
  )
};

export default FeaturedPortfolios;
