import React from "react";
import { Button, Modal } from "antd";
import { TextField } from "@material-ui/core";
import classnames from "classnames";
import _ from "lodash";
import queryString from "query-string";
import { mapProp, doAction } from "module-reaction";
import {
  module_busines_payment,
  ModuleBusinessPayment,
  GetPayInfoAction,
  setPromotionAction,
  applyPromotionCodeAction,
} from "../../models/model_business_payment";
import { getPayKey, paySubscribe, payContact } from "../../api/Pay";
import { updateCanvasStatus, getCanvas } from "../../api/Canvas";
import CValidateForm, {
  ValidateForm,
} from "../../components/component_validate_form";
import StripeModal from "../../components/component_stripe_modal";
import IconBillInfo from "../../assets/images/ic_ professional_info.svg";
import IconContactInfo from "../../assets/images/ic_contact_info.svg";
import CanvasME from "../../assets/images/logo.svg";
import Logo from "../../assets/images/logo_white_black_bg.svg";
import {
  router_my_dashboard,
  CouponType,
  router_post_job_opp,
} from "../../utils/enum";
import "./business_canvas_payment.less";

import { getTrackId } from "../../utils/authStorage";
import SchoolInfo from "../create_school_canvas/school_info";
import CompanyInfo from "./company_info";
import {
  module_school_info,
  MODULE_SCHOOL_INFO,
} from "../../models/model_school_info";
import { module_company_info } from "../../models/model_company_info";
import { modle_user } from "../../models/model_user";
import GetStartedOverlay from "../onboarding/get_start_overlay";
import { checkOverlay, isAdminEditing } from "../../utils/utils";
import { SourceName } from "../../utils/enum";
import AdminSubscriptionForm from "../../components/component_admin_subscription_form";
import { isSalonCentric, addScSalonId } from "../../api/SalonCentric";

const paymentMethodText = {
  business: {
    title: "",
    intro:
      "Select your plan below based on number of opportunities you are posting:",
    paymentType: [
      {
        value: 1,
        name: "Business Canvas & Opportunity Posts",
        premium: true,
        details: [
          <>
            FREE <strong>Business Canvas</strong> to showcase your staff
            members, benefits, awards and more
          </>,
          "Highlight your staff members, benefits, awards and more",
          "Attract talent and recruit candidates from the largest beauty-pro talent pools",
          "Fexible opportunity post bundle + a centralized dashboard to manage your candidates",
        ],
      },
    ],
  },
  school: {
    title: "Unlock your school dashboard to empower your students and soar.",
    intro:
      "A complete package for your school, including all essential features to help you manage your students, staff, recruit and gain better insights.",
    paymentType: [
      {
        value: 1,
        name: "School Dashboard, School Canvas  & 1 Opportunity post",
        premium: true,
        details: [
          <>
            A beautiful <strong>School Canvas</strong> to highlight your offers
            and attract students
          </>,
          "Flexible opportunity post bundle that meet your hiring needs",
          <>
            A powerful <strong>School dashboard</strong> to help centralize and
            better manage your students and staff
          </>,
          "A FREE opportunity post/month if you purchase this school package",
        ],
      },
    ],
  },
};

@mapProp(module_busines_payment)
@mapProp(module_school_info)
@mapProp(
  module_company_info,
  "slug:coSlug",
  "companyName",
  "contactNumber:coContactNumber",
  "businessCategory",
  "categoryOptions",
  "defaultLocation:coDefaultLocation",
  "extraLocation:coExtraLocation"
)
@mapProp(modle_user)
export default class BusinessPayment extends React.Component<
  ModuleBusinessPayment,
  {}
> {
  methodFormRef: React.RefObject<ValidateForm>;
  contactFormRef: React.RefObject<ValidateForm>;
  schoolRef: React.RefObject<SchoolInfo>;
  companyRef: React.RefObject<CompanyInfo>;

  constructor(props: any) {
    super(props);
    this.methodFormRef = React.createRef();
    this.contactFormRef = React.createRef();
    this.schoolRef = React.createRef();
    this.companyRef = React.createRef<CompanyInfo>();
  }

  async componentWillMount() {
    const currentCanvasType =
      window.location.pathname.indexOf("school") >= 0 ? "school" : "business";
    this.setState({
      currentCanvasType,
      selectedType: 1,
    });

    const isFromSalonCentric = await isSalonCentric();
    this.setState({
      isFromSalonCentric,
    });
  }

  componentDidMount() {
    window.onresize = () => {
      this.setState({ isMobile: window.innerWidth < 1024 });
    };
    doAction(GetPayInfoAction, this.state.currentCanvasType);
    const { isPostOpportunity } = queryString.parse(window.location.search);
    if (isPostOpportunity) {
      this.stateOnChange("selectedType", 1);
    }
    if (getTrackId() && Number(getTrackId()) === 1) {
      this.setState(
        {
          promotionValue: "JPMS30",
        },
        () => {
          doAction(setPromotionAction, {
            promotionCode: "JPMS30",
          });
          doAction(applyPromotionCodeAction);
        }
      );
    }
    this.getCanvasId();
    if (checkOverlay()) {
      this.setState({ getStartedOverlay: true });
    }
  }
  componentWillUnmount() {
    window.onresize = () => {};
  }

  state = {
    isMobile: window.innerWidth <= 1024,
    currentCanvasType: "business",
    selectedType: 0,
    selectedPost: 0,
    stripeVisible: false,
    billingDetail: this.props.billingValue,
    stripeKey: "",
    visibleContactSuccess: false,
    submitLoading: false,
    canvasId: 0,
    promotionValue: "",
    getStartedOverlay: false,
    finishedOverlay: false,
    isForExisting: false,
    showMore: false,
    currentStep: 1,
    agreedToTerms: false,
    isFromSalonCentric: false,
    scSalonId: null,
    hireAPro: false,
  };

  private handleCheckboxChange = (event) => {
    this.setState({ ...this.state, agreedToTerms: event.target.checked });
  };

  private getCanvasId = async () => {
    const slug = _.get(this.props, "match.params.id");
    if (!slug) return;
    const res = await getCanvas(slug);
    if (res && res.id) {
      this.setState({ ...this.state, canvasId: res.id, isForExisting: true });
    }
  };

  private getCanvasIdViaSlug = async (slug: string) => {
    const res = await getCanvas(slug);
    return new Promise<number>((resolve) => {
      if (res && res.id) {
        this.setState({ ...this.state, canvasId: res.id }, () => {
          resolve(res.id);
        });
      } else {
        resolve(0);
      }
    });
  };

  private insertScSalonId = async (canvasId: number, scSalonId: any) => {
    console.log(canvasId, scSalonId);
    if (canvasId && scSalonId) {
      await addScSalonId(canvasId, Number(scSalonId));
    }
  };

  stateOnChange = (name: string, value: any) => {
    this.setState({ [name]: value });
  };

  currentPrice = () => this.props.priceOptions[this.state.selectedPost] || {};

  fullSubmit = async () => {
    const { canvasId, currentCanvasType } = this.state;
    const isSchool = currentCanvasType === "school";
    let id = canvasId;
    let slug = this.getSlug();
    if (slug) {
      id = await this.getCanvasIdViaSlug(slug);
    }
    if (id) {
      this.submitBilling();
    } else if (!id && isSchool && this.schoolRef.current) {
      this.setState({ submitLoading: true });
      const ret = this.schoolRef?.current?.submitSchoolInfo();
      if (ret) {
        setTimeout(async () => {
          this.setState({ submitLoading: false });
          slug = this.getSlug();
          id = await this.getCanvasIdViaSlug(slug);
          this.submitBilling();
        }, 3000);
      } else {
        this.setState({ submitLoading: false });
      }
    } else if (!id && !isSchool && this.companyRef.current) {
      this.setState({ submitLoading: true });
      const ret = this.companyRef?.current?.submitCompanyInfo();
      if (ret) {
        setTimeout(async () => {
          this.setState({ submitLoading: false });
          slug = this.getSlug();
          id = await this.getCanvasIdViaSlug(slug);
          this.submitBilling();
        }, 3000);
      } else {
        this.setState({ submitLoading: false });
      }
    }
  };

  submitBilling = async () => {
    if (!this.state.canvasId) {
      return;
    }

    await this.insertScSalonId(this.state.canvasId, this.state.scSalonId);

    if (this.props.stripeId) {
      this.subscribePayment();
    } else {
      this.methodFormRef
        .current!.handleSubmit()
        .then(async (res: any) => {
          const { stripeKey } = this.state;
          let getKey: any;
          if (!stripeKey) {
            getKey = await getPayKey();
          }
          this.setState({
            stripeVisible: true,
            stripeKey: stripeKey || getKey.key,
            billingDetail: res,
          });
        })
        .catch((err: any) => {
          //need delete when deploy
        });
    }
  };

  subscribePayment = async (paymentMethod?: string) => {
    this.setState({ stripeVisible: false, submitLoading: true });
    const { promotionResult, promotionCode } = this.props;
    const isSchool = this.state.currentCanvasType === "school";
    let canvasId = this.state.canvasId;
    if (!canvasId) {
      canvasId = await this.getCanvasIdViaSlug(this.getSlug());
    }
    if (!canvasId) {
      this.setState({ submitLoading: false });
      return;
    }
    const postParams: any = {
      canvas_id: canvasId,
      price_id: this.currentPrice().price_id,
      hire_a_pro: this.state.hireAPro,
    };
    if (paymentMethod) {
      postParams.payment_method = paymentMethod;
      postParams.email = this.state.billingDetail.email;
    }
    if (promotionResult.type && promotionCode.length) {
      postParams.coupon = promotionCode;
    }
    paySubscribe(postParams)
      .then((res) => {
        if (_.get(res, "status") === "succeeded") {
          updateCanvasStatus(canvasId).then(() => {
            this.setState({ submitLoading: false });

            if (isSchool) {
              if (this.state.isForExisting) {
                this.props.history.push(router_my_dashboard);
              } else {
                this.setState({ finishedOverlay: true });
              }
            } else {
              this.props.history.push(
                `${router_post_job_opp}?id=${canvasId}&overlay=true`
              );
            }
          });
        } else {
          this.setState({ submitLoading: false });
        }
      })
      .catch((err) => {
        if (err && err.message === "Invalid Coupon") {
          doAction(setPromotionAction, {
            promotionResult: {
              type: CouponType.INVALID,
              value: "",
            },
          });
        }
        this.setState({ submitLoading: false });
      });
  };

  submitContactInfo = () => {
    this.contactFormRef
      .current!.handleSubmit()
      .then((res: any) => {
        this.setState({ submitLoading: true });
        const canvasId = this.state.canvasId;
        if (!canvasId) return;
        const { firstName, lastName, phoneNumber, email } = res;
        const postData = {
          canvasId,
          firstName,
          lastName,
          phoneNumber,
          email,
        };
        payContact(postData)
          .then((res) => {
            this.setState({
              submitLoading: false,
              visibleContactSuccess: true,
            });
          })
          .catch(() => this.setState({ submitLoading: false }));
      })
      .catch((err: any) => {});
  };

  private getCouponValue = () => {
    const {
      promotionResult: { type, value },
    } = this.props;
    const amount = this.currentPrice().amount;
    if (type === CouponType.AMOUNT_OFF) {
      const valueFormatted = value / 100;
      return valueFormatted < amount ? valueFormatted : amount;
    }
    if (type === CouponType.PERCENT_OFF) {
      return value < 100 ? amount * (value / 100) : amount;
    }
    return 0;
  };

  private getSource = () => {
    return new URLSearchParams(window.location.search).get("source");
  };

  render() {
    const {
      selectedType,
      selectedPost,
      isMobile,
      currentCanvasType,
      promotionValue,
      canvasId,
      isFromSalonCentric,
      scSalonId,
    } = this.state;
    const {
      billingFormElement,
      billingValue,
      contactFormElement,
      contactInfoValue,
      priceOptions,
      stateOptions,
      stripeId,
      promotionResult,
      curUser,
    } = this.props;
    const isSchool = currentCanvasType === "school";
    const currentPayText = _.get(paymentMethodText, [currentCanvasType]);
    const overlayType = isSchool ? "school" : "business";
    const twoMonthsFree = this.getSource() == SourceName.WELLA ? true : false;

    const sixMonth = currentCanvasType.includes("business") && true;

    return (
      <div className="business-payment-wrap new-sub">
        {this.state.currentStep == 1 && (
          <>
            <div className="logo">
              <img src={CanvasME} alt="Logo" />
            </div>
            {sixMonth && (
              <div className="payment-header">
                <h2>Canvas ME Subscriptions Are on a Bi-Annual Commitment</h2>
                <p>
                  Recruitment Marketing is a strategy. Our proven methodology
                  highlights the best results to those that are committed to
                  consistent recruitment and funnel building
                </p>
              </div>
            )}
            <div className="title-wrap">
              <div className={"common-title"}>{currentPayText.title}</div>
              <div className="intro">{currentPayText.intro}</div>
            </div>
            {twoMonthsFree && (
              <div className="two-months-free">
                <h2>
                  Enjoy 33% off for the next 6 months which equates to 2 months
                  free!!! Use coupon code: WELLA2
                </h2>
              </div>
            )}
            <div className="payment-content-wrap">
              <div className="payment-wrap">
                <div className="custom-post">
                  <div className="post-rule">
                    {isSchool && (
                      <div className="post-wrap">
                        <div className="post-items">
                          {priceOptions.map(
                            (item: any, index: number) =>
                              item.job_count == 1 && (
                                <span
                                  className="post-item active only"
                                  key={index}
                                  onClick={() =>
                                    this.stateOnChange("selectedPost", index)
                                  }
                                >
                                  <div className="post-item-number">
                                    {item.job_count}
                                  </div>
                                  <div className="post-item-opp-txt">
                                    Career opp{item.job_count > 1 && "s"}
                                  </div>
                                  <div className="post-item-price">
                                    ${item.amount}
                                  </div>
                                  <div className="post-item-per-txt">
                                    Per {sixMonth ? <>6 Months</> : <>Month</>}
                                  </div>
                                </span>
                              )
                          )}
                        </div>
                      </div>
                    )}
                    {!isSchool && (
                      <div className="post-wrap">
                        <div className="post-items">
                          {priceOptions.map((item: any, index: number) =>
                            item.job_count < 4 ? (
                              <span
                                className={classnames("post-item", {
                                  active: index === selectedPost,
                                })}
                                key={index}
                                onClick={() =>
                                  this.stateOnChange("selectedPost", index)
                                }
                              >
                                <div className="post-item-number">
                                  {item.job_count}
                                </div>
                                <div className="post-item-opp-txt">
                                  Career opp{item.job_count > 1 && "s"}
                                </div>
                                <div className="post-item-price">
                                  ${item.amount}
                                </div>
                                <div className="post-item-per-txt">
                                  Per {sixMonth ? <>6 Months</> : <>Month</>}
                                </div>
                              </span>
                            ) : (
                              <span
                                className={classnames("post-item", {
                                  active: index === selectedPost,
                                })}
                                key={index}
                                onClick={() =>
                                  this.stateOnChange("selectedPost", index)
                                }
                              >
                                <div className="post-item-number">4+</div>
                                <div className="post-item-opp-txt">
                                  Career opps
                                </div>
                                <div className="post-item-price">
                                  Contact Us
                                </div>
                                <div className="post-item-per-txt">
                                  For Pricing
                                </div>
                              </span>
                            )
                          )}
                        </div>
                        {/* <br />
                        <div
                          className="checkbox-input"
                          style={{
                            backgroundColor: this.state.hireAPro
                              ? "#2d2e3d"
                              : "#f0f0f0",
                            color: this.state.hireAPro ? "white" : "black",
                            padding: "10px 20px",
                            borderRadius: "20px",
                            cursor: "pointer",
                            display: "inline-block",
                          }}
                        >
                          <label>
                            <input
                              type="checkbox"
                              style={{ margin: "0 10px" }}
                              checked={this.state.hireAPro}
                              onChange={(e) => {
                                this.setState({
                                  hireAPro: e.target.checked,
                                });
                              }}
                            />
                            <b>$99 Hire a Pro</b>
                          </label>
                        </div> */}
                      </div>
                    )}
                    {selectedPost !== priceOptions.length - 1 && (
                      <div className="promotion-code-wrap">
                        <TextField
                          key="promotionCode"
                          label="Promotion code"
                          variant="filled"
                          className="material-input promotion-code-input"
                          error={promotionResult.type === CouponType.INVALID}
                          helperText="Invalid code, please try another one"
                          value={promotionValue}
                          onChange={(e) => {
                            const val = e.target.value;
                            this.setState({
                              promotionValue: e.target.value,
                            });
                            doAction(setPromotionAction, {
                              promotionCode: e.target.value,
                            });
                            if (!val.length) {
                              doAction(setPromotionAction, {
                                promotionResult: {
                                  type: -1,
                                  value: "",
                                },
                              });
                            }
                          }}
                        />
                        <Button
                          className="btn-primary"
                          onClick={() => {
                            doAction(applyPromotionCodeAction);
                          }}
                        >
                          Apply code
                        </Button>
                        {promotionResult.type > 0 &&
                          selectedPost !== priceOptions.length - 1 && (
                            <div className="successful-promo">
                              <div className="success-txt">
                                Promotion Applied Successfully!
                              </div>
                              <div className="success-amount">
                                ${this.currentPrice().amount}-$
                                {this.getCouponValue()} = $
                                {(
                                  this.currentPrice().amount -
                                  this.getCouponValue()
                                ).toFixed(2)}
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                    {isFromSalonCentric && (
                      <>
                        <div className="salon-centric-input">
                          <label>
                            <b>SalonCentric Account number (Sold to Code) (10 digits) </b>
                            <input
                              value={this.state.scSalonId}
                              type="text"
                              maxLength={10}
                              pattern="\d*"
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value === "" || /^[0-9\b]+$/.test(value)) {
                                  this.setState({
                                    scSalonId: value,
                                  });
                                }
                              }}
                            />
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedPost === priceOptions.length - 1 ? (
              <div className="payment-content-wrap">
                <div className="payment-wrap">
                  <div className="contact-info">
                    Please fill out your Contact Info and we will reach out to
                    you
                    <div className="no-padding title-with-icon">
                      <img src={IconContactInfo} alt="" />
                      Contact Information
                    </div>
                    <CValidateForm
                      wrappedComponentRef={this.contactFormRef}
                      formElement={contactFormElement(isMobile)}
                      defaultValue={contactInfoValue}
                    />
                  </div>
                  <Button
                    className="next-btn btn-primary"
                    onClick={this.submitContactInfo}
                    loading={this.state.submitLoading}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <Button
                  className=" next-btn btn-primary ant-button"
                  onClick={() => [
                    this.stateOnChange("currentStep", 2),
                    window.scrollTo(0, 0),
                  ]}
                >
                  NEXT TO PAYMENT
                </Button>
                {/* Removing for now */}
                {/* { isAdminEditing(curUser) && (
                    <Button
                    className=" next-btn btn-primary ant-button offline-payment-btn"
                    onClick={() => [
                      this.stateOnChange("currentStep", 3),
                      window.scrollTo(0, 0),
                    ]}
                  >
                    EXTERNAL PAYMENT
                  </Button>
                )} */}
              </>
            )}
          </>
        )}

        {/* Removing this for now */}
        {/* {this.state.currentStep == 3 && (
          <div className="offline-payment">
            <div className="logo">
              <img src={CanvasME} alt="Logo"/>
            </div>
            <h1>External Payment</h1>
            <AdminSubscriptionForm canvasId={canvasId} />
            <Button
              className=" next-btn btn-primary ant-button"
              onClick={() => [
                this.stateOnChange("currentStep", 1),
                window.scrollTo(0, 0),
              ]}
            >
              Back
            </Button>
          </div>
        )} */}

        {this.state.currentStep == 2 && (
          <>
            <div className="product-summary">
              <div className="step-two-logo">
                <img src={CanvasME} alt="Logo" />
              </div>
              <div className="review-sub title-with-icon">
                Review Your Subscription
              </div>
              <div className="plan-row">
                <div
                  className="switch-plan"
                  onClick={() => this.stateOnChange("currentStep", 1)}
                >
                  <img src={Logo} alt="Logo" />
                  <span>SWITCH PLAN</span>
                </div>
                <div className="selected-options">
                  <div className="num-opps">
                    {isSchool ? 1 : this.currentPrice().job_count} Career{" "}
                    {this.currentPrice().job_count > 1
                      ? "Opportunities"
                      : "Opportunity"}
                  </div>
                  <div className="price">
                    ${this.currentPrice().amount - this.getCouponValue()}
                    {sixMonth ? <> for 6 Months</> : <> /MONTH</>}
                  </div>
                  <div className="disclosure">
                    {sixMonth ? (
                      <>
                        <b>
                          You will be charged{" "}
                          <u>
                            $
                            {this.currentPrice().amount - this.getCouponValue()}
                          </u>{" "}
                          today.
                          <br />
                          Your Next Bi-Annual Recurring Payment
                          <br />
                          {/* Bring this back when it will be correct depending on if coupon is duration once, repeating or forever */}
                          {/* of ${this.currentPrice().amount - this.getCouponValue()}  */}
                          will be on{" "}
                          <u>
                            {new Date(
                              new Date().setMonth(new Date().getMonth() + 6)
                            ).toLocaleDateString()}
                          </u>
                          .
                        </b>
                      </>
                    ) : (
                      <>
                        Your Credit card will automatically be debited on the
                        same day each month.
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {isSchool && !this.state.isForExisting && (
              <SchoolInfo
                id={this.props.id}
                payOrderStatus={this.props.payOrderStatus}
                ref={this.schoolRef}
                isInline={true}
                module={MODULE_SCHOOL_INFO}
                programOptions={this.props.programOptions}
                slug={this.getSlug()}
                school={this.props.school}
                title={this.props.title}
                contactNumber={this.props.contactNumber}
                scSalonId={this.props.scSalonId}
                defaultLocation={this.props.defaultLocation}
                extraLocation={this.props.extraLocation}
              ></SchoolInfo>
            )}
            {!isSchool && !this.state.isForExisting && (
              <CompanyInfo
                id={this.props.id}
                payOrderStatus={this.props.payOrderStatus}
                ref={this.companyRef}
                curUser={this.props.curUser}
                history={this.props.history}
                slug={this.getSlug()}
                categoryOptions={this.props.categoryOptions}
                businessCategory={this.props.businessCategory}
                companyName={this.props.companyName}
                contactNumber={this.props.coContactNumber}
                scSalonId={this.props.scSalonId}
                defaultLocation={this.props.coDefaultLocation}
                extraLocation={this.props.coExtraLocation}
              ></CompanyInfo>
            )}
            <br />
            <div className="pay-method">
              <div className="title-with-icon">
                <img src={IconBillInfo} alt="" />
                Billing Information
              </div>
              <CValidateForm
                wrappedComponentRef={this.methodFormRef}
                formElement={billingFormElement(isMobile, stateOptions)}
                defaultValue={billingValue}
                disabledForm={!!stripeId}
              />
            </div>
            {sixMonth ? (
              <div className="confirm-txt">
                I understand that I am signing up for a 6 month subscription. I
                understand that my Card will be charged biannually for these
                services after the 6 months unless cancelled. All subscription
                plans are non-refundable according to our&nbsp;
                <a href="/terms" target="_blank" rel="noopener noreferrer">
                  terms and conditions.
                </a>
              </div>
            ) : (
              <div className="confirm-txt">
                By clicking confirm I agree to subscribe to this Canvas ME
                monthly subscription. I understand that my Card will be charged
                monthly for these services unless cancelled. All subscription
                plans are non-refundable according to our&nbsp;
                <a href="/terms" target="_blank" rel="noopener noreferrer">
                  terms and conditions.
                </a>
              </div>
            )}
            <div className="confirm-txt">
              <input
                type="checkbox"
                id="agree"
                name="agree"
                checked={this.state.agreedToTerms}
                onChange={this.handleCheckboxChange}
              />
              <label htmlFor="agree">
                {" "}
                I agree to the terms and conditions
              </label>
            </div>
            <Button
              className="next-btn btn-primary"
              onClick={this.fullSubmit}
              loading={this.state.submitLoading}
              disabled={this.state.agreedToTerms ? false : true}
            >
              CONFIRM PAYMENT
            </Button>
          </>
        )}

        <StripeModal
          visible={this.state.stripeVisible}
          afterPay={this.subscribePayment}
          onClose={() => this.setState({ stripeVisible: false })}
          billingDetail={this.state.billingDetail}
          stripeKey={this.state.stripeKey}
        />

        <Modal
          footer={null}
          visible={this.state.visibleContactSuccess}
          closable={false}
          centered={true}
          className="payment-contact-success-modal"
          width="400px"
        >
          <div className="common-title">Message sent!</div>
          <div className="intro">
            Your Contact Information has been submitted successfully. <br />
            We will reach out to you in 3 working days.
          </div>
          <Button
            type="primary"
            onClick={() => this.props.history.push(router_my_dashboard)}
          >
            Return to dashboard
          </Button>
        </Modal>

        <Modal
          visible={this.state.getStartedOverlay}
          closable={true}
          onCancel={() => this.setState({ getStartedOverlay: false })}
          maskClosable={true}
          footer={null}
          centered={true}
        >
          <GetStartedOverlay
            type={overlayType}
            close={() => this.setState({ getStartedOverlay: false })}
          ></GetStartedOverlay>
        </Modal>

        <Modal
          visible={this.state.finishedOverlay}
          closable={true}
          onCancel={() => this.props.history.push(router_my_dashboard)}
          maskClosable={true}
          footer={null}
          centered={true}
        >
          <div className="cm-finish-modal">
            <h2>You've successfully created and subscribed your school!</h2>
            <Button
              className="btn-primary"
              onClick={() => this.props.history.push(router_my_dashboard)}
            >
              Go to dashboard
            </Button>
            <Button className="btn-secondary" onClick={() => this.refresh()}>
              Set up another school
            </Button>
          </div>
        </Modal>
      </div>
    );
  }

  private getSlug = (): string => {
    const { slug, coSlug } = this.props;
    return slug ? slug : coSlug;
  };

  private refresh = () => {
    window.location.reload();
  };
}
