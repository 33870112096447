import { TextField } from "@material-ui/core";
import { Button } from "antd";
import React, { useState } from "react";
import { getStaticAssetPath } from "../../../utils/utils";
import { global_router } from "../../../routers";
import { router_get_started } from "../../../utils/enum";
import ipadContent from "../../../assets/images/home/ipad-portfolio.jpg";
import ScrollingIpad from "./scrolling_ipad";
import StudentProWatch from "./studentpro_watch";
import StudentProVideo from "./studentpro_video";
import VimeoPlayer from "../../../components/component_vimeo_player";
import "./studentpro_hero.less"

interface Props {
  setCurPlayUrl: Function;
  source: string;
}

const StudentProHero: React.FC<Props> = ({
  setCurPlayUrl, source
}) => {
  const [email, setEmail] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const alternateSource = urlParams.get('source') ? urlParams.get('source') : source;
  const rep = urlParams.get('rep') ? urlParams.get('rep') : null;

  const initSignup = () => {
    if (!email) {
      global_router.history.push(`${router_get_started}?source=${alternateSource}&rep=${rep}`);
    }

    global_router.history.push(
      `${router_get_started}?prefilled=${encodeURIComponent(
        email
      )}&source=${source}&rep=${rep}`
    );
  };

  return (
    <div className="cm-sp-headline-root cm-flex-responsive-row">
      <div className="cm-sp-hero-content cm-hero-header-txt">
        <h1>Level up your career and become a brand educator</h1>
        <ol className="cm-hero-list">
          <li>Create your free Canvas ME account</li>
          <li>Browse brand educator openings</li>
          <li>Get discovered by brands like Matrix, Pulp Riot, and Redken</li>
        </ol>
        <form onSubmit={initSignup} className="cm-flex-responsive-row cm-full-width cm-flex-v-center">
          <TextField
            key="email"
            label="Email"
            variant="filled"
            className="material-input input cm-flex-1"
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            htmlType="submit"
            className="btn-primary btn-sign-up">
            Join in less than 3 minutes
          </Button>
        </form>

      </div>
      <div className="cm-sp-hero-img-wrapper cm-studentpro-vid">
        <VimeoPlayer vidSrc="https://player.vimeo.com/video/857975749?h=9464d9afa2&color=0064b4&title=0&byline=0&portrait=0" />
      </div>
      
    </div>
  );
};

export default StudentProHero;
