import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Breadcrumbs,
  Grid,
} from "@material-ui/core";
import React from "react";
import _ from "lodash";
import "./membership.less";
import { Link } from "react-router-dom";
import {
  router_membership,
  router_membership_invoice_list,
} from "../../../utils/enum";
import { previewAddress } from "../../../utils/utils";
import { getInvoice } from "../../../api/Pay";
import { getCustomized } from "../../../api/Options";
import moment from "moment";
import { mapProp } from "module-reaction";
import { modle_user } from "../../../models/model_user";

@mapProp(modle_user)
export class InvoiceView extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      invoice: null,
      stateOptions: [],
    };
  }

  public componentDidMount() {
    this.getInvoice();
  }

  public render() {
    let content;
    const invoice = this.state.invoice;

    if (invoice == null) {
      content = <div className="no-content-message">Loading...</div>;
    } else {
      content = this.details();
    }

    return (
      <div className="membership-view">
        {this.breadcrumbs()}
        <h1 className="common-title">{this.props.title}</h1>
        <div className="invoice-view">{content}</div>
      </div>
    );
  }

  private details() {
    const invoice = this.state.invoice;
    let content = null;

    if (invoice == null) {
      content = <div className="no-content-message">Loading...</div>;
    } else {
      content = (
        <>
          {this.info()}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {this.issueTo()}
            </Grid>
            <Grid item xs={12} sm={6}>
              {this.issueBy()}
            </Grid>
          </Grid>
          {this.summary()}
        </>
      );
    }

    return content;
  }

  private info() {
    const invoice = this.state.invoice;

    return (
      <section>
        <h2 className="common-title">Invoice Info</h2>
        <TableContainer className="information-table list-table-wrap issue-to-table">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Invoice Number</TableCell>
                <TableCell>{invoice.number}</TableCell>
                <TableCell>Date Paid</TableCell>
                <TableCell>{moment(invoice.paidDate).format("lll")}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    );
  }

  private issueTo() {
    const invoice = this.state.invoice;
    const paymentIntent = _.get(invoice, "payment_intent") || {};
    const paymentInfo = _.get(paymentIntent, "payment_method") || {};
    const billingDetails = _.get(paymentInfo, "billing_details") || {};
    const address = _.get(billingDetails, "address") || {};
    return (
      <section>
        <h2 className="common-title">Issue To</h2>
        <TableContainer className="information-table list-table-wrap">
          <Table className="issue-by-table">
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{invoice.customer_name || this.props.curUser.profile?.firstName + ' ' + this.props.curUser.profile?.lastName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>EMAIL</TableCell>
                <TableCell>{invoice.customer_email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Billing ADDRESS</TableCell>
                <TableCell>
                  {previewAddress(this.state.stateOptions, address)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    );
  }

  private issueBy() {
    const invoice = this.state.invoice;

    return (
      <section>
        <h2 className="common-title">Issue By</h2>
        <TableContainer className="information-table list-table-wrap">
          <Table className="issue-to-table">
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{invoice.account_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>EMAIL</TableCell>
                <TableCell>admin@canvasme.com</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Billing ADDRESS</TableCell>
                <TableCell>
                  P.O. Box 469, Florham Park, NJ 07932
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    );
  }

  private summary() {
    const {
      tax,
      total,
      payment_intent = {},
    } = this.state.invoice;
    const paymentInfo = _.get(payment_intent, "payment_method") || {};
    const { card = {} } = paymentInfo;
    const formattedTotal = total / 100;
    const formattedTax = tax ? tax / 100 : 0;
    const preTaxAmount = formattedTotal - formattedTax;
    return (
      <section className="summary-section">
        <h2 className="common-title">Product Summary</h2>
        <TableContainer className="information-table list-table-wrap">
          <Table className="product-summary-table">
            <TableBody>
              <TableRow>
                <TableCell>{this.state.invoice.lines.data[0].description}</TableCell>
                <TableCell>${preTaxAmount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tax</TableCell>
                <TableCell>${formattedTax}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell>${formattedTotal}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className="footer">
          * Paid by {card.brand} ending {card.last4} which expires {" "}
          {card.exp_month}/{card.exp_year}
        </div>
      </section>
    );
  }

  private breadcrumbs() {
    return (
      <Breadcrumbs>
        <Link to={router_membership}>Membership</Link>
        <Link to={router_membership_invoice_list}>Invoice History</Link>
        <span>{this.props.title}</span>
      </Breadcrumbs>
    );
  }

  private async getInvoice() {
    const invoice = await getInvoice(this.props.match.params.id);
    const options = await getCustomized(true, ["state"]);
    this.setState({
      invoice,
      stateOptions: options.state,
    });
  }
}
