import React from 'react';

import "./home_membership.less";
import checkBullet from "../../../assets/images/home/check-bullet.png";

export class HomeMembership extends React.Component<{}, {}> {
  render() {
    return <div className="cm-home-membership cm-home__section cm-flex-center cm-flex-column">
      <h2 className="cm-gutter-bottom-xxl">
        When you join Canvas ME you are joining a community of elite beauty professionals driven to live out the beauty career of their dreams.
      </h2>
      <div className="cm-flex-responsive-row">
        <div className="cm-home-membership__descriptions">
          <p>
            Whether you are a <b>business owner</b> looking for talent OR looking to level up in your career, Canvas ME is where you belong.
          </p>
          <p>
            If you are a <b>beauty professional</b> looking for your next work family OR want to find a side hustle gig, Canvas ME is for you.
          </p>
          <p>
            If you are a <b>beauty student</b> looking to level up through a mentorship OR land your first full-time role join us on Canvas ME.
          </p>
          <p>
            Lastly, if you are a <b>beauty school</b> looking to truly empower your students join the community that gives them a voice — Canvas ME.
          </p>
        </div>
        <div className="cm-home-membership__list">
          <h3>Membership Includes:</h3>
          <div className="cm-flex-row">
            <img src={checkBullet} alt="check bullet" />
            <p>Free Portfolio/Resume Webpage</p>
          </div>
          <div className="cm-flex-row">
            <img src={checkBullet} alt="check bullet" />
            <p>Free Professional Webpage</p>
          </div>
          <div className="cm-flex-row">
            <img src={checkBullet} alt="check bullet" />
            <p>Free Live Webinars</p>
          </div>
          <div className="cm-flex-row">
            <img src={checkBullet} alt="check bullet" />
            <p>Exclusive Partner Discounts</p>
          </div>
          <div className="cm-flex-row">
            <img src={checkBullet} alt="check bullet" />
            <p>Exclusive Career Opportunities</p>
          </div>
        </div>
      </div>
    </div>;
  }
}
