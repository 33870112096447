import { ModuleStore, ModuleAction } from "module-reaction";
import { getCanvasMedias } from "../api/Insta";
export const MODULE_HOMEPAGE = "module_homepage";

export interface ModuleHomepage extends ModuleStore {
  insList: any[];
}

export const module_homepage: ModuleHomepage = {
  module: MODULE_HOMEPAGE,
  insList: [],
};

export const GetInsListAction: ModuleAction<any, ModuleHomepage> = {
  module: MODULE_HOMEPAGE,
  process: async () => {
    const res = await getCanvasMedias(8);
    if (res && res.medias) {
      return { insList: res.medias };
    }
    return { insList: [] };
  },
};
