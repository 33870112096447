type Callback = (profile: GoogleProfile) => void;
type GoogleProfile = {
  idToken: string;
  userId: string;
  email: string;
  name: string;
  profilePhotoUrl: string;
};

let googleAuth: gapi.auth2.GoogleAuth;
let callBack: Callback;

const onGoogleSignIn = () => (googleUser: gapi.auth2.GoogleUser) => {
  if (!googleAuth.isSignedIn.get()) return;
  try {
    const basicProfile = googleUser.getBasicProfile();
    const profile = {
      idToken: googleUser.getAuthResponse().id_token,
      userId: googleUser.getId(),
      email: basicProfile.getEmail(),
      name: basicProfile.getName(),
      profilePhotoUrl: basicProfile.getImageUrl(),
    };
    callBack(profile);
  } catch (e) {
    console.warn(e);
  }
};

export const onClickGoogleLogin = (cb: Callback) => {
  callBack = cb;
  const _refreshLogin = () => {
    googleAuth.signIn();
  };
  if (googleAuth) {
    _refreshLogin();
  } else {
    const gapi = window.gapi;
    if (!gapi) {
      return;
    }
    gapi.load("auth2", () => {
      gapi.auth2
        .init({
          client_id:
            "742555882273-905i0pacsp6lh7l5845f7bsjd7ps62nm.apps.googleusercontent.com",
          // ["client_id"]:
          //   process.env.NODE_ENV === "production"
          //     ? "373179665993-9bb0n7ssneuh8otv4smui4ruegeaqt7o.apps.googleusercontent.com"
          //     : "988657179854-giskvr3knoh9gupkckcuu52jdiotui8a.apps.googleusercontent.com",
          scope: "profile email",
        })
        .then((g) => {
          googleAuth = g;
          googleAuth.currentUser.listen(onGoogleSignIn());
          _refreshLogin();
        });
    });
  }
};
