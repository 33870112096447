import { ModuleStore, ModuleAction, KV, plusAction } from "module-reaction";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { getCustomized } from "../api/Options";
import { createCanvas, getCanvas, editCanvas } from "../api/Canvas";
import { CanvasType } from "../utils/enum";
export const MODULE_COMPANY_INFO = "module_company_info";

export interface LocationInfo {
  uuid: string;
  address: string;
  chairs: number;
  areaUnit: string;
  area: number;
  employee: any[];
  employeeNumber: number;
  country?: string;
  countryShort?: string;
  state?: string;
  stateShort?: string;
  city?: string;
  coordinates?: number[];
}

export interface Employee {
  id?: number;
  image?: string;
  name?: string;
  authority?: number; // see SchoolRole
  email?: string;
}

export interface ModuleCompanyInfo extends ModuleStore {
  id: number;
  payOrderStatus: string;
  slug: string;
  companyName: string;
  contactNumber: string;
  scSalonId: number | null;
  businessCategory: any[];
  categoryOptions: any[];
  defaultLocation: LocationInfo;
  extraLocation: LocationInfo[];
}

export const module_company_info: ModuleCompanyInfo = {
  module: MODULE_COMPANY_INFO,
  id: 0,
  payOrderStatus: 'none',
  slug: "",
  companyName: "",
  contactNumber: "",
  scSalonId: null,
  businessCategory: [],
  categoryOptions: [],
  defaultLocation: {
    uuid: uuidv4(),
    areaUnit: "ft",
    employee: [] as any[],
  } as LocationInfo,
  extraLocation: [],
};

export const InitDataAction: ModuleAction<any, ModuleCompanyInfo> = {
  module: MODULE_COMPANY_INFO,
  process: async (slug: string) => {
    if (slug) {
      const canvasInfo = await getCanvas(slug);
      if (canvasInfo) {
        const cates =
          _.map(canvasInfo.detail.categories, (item) => {
            return {
              id: item.optionId,
              name: item.name,
            };
          }) || [];
        let pageData = {
          id: canvasInfo.id,
          payOrderStatus: canvasInfo.payorder.status,
          slug: slug,
          companyName:
            (canvasInfo.introduction && canvasInfo.introduction.name) || "",
          scSalonId: 
            (canvasInfo.detail && canvasInfo.detail.scSalonId) || "",
          contactNumber:
            (canvasInfo.detail && canvasInfo.detail.primaryContactNumber) || "",
          businessCategory: cates,
        } as any;
        if (canvasInfo.detail && !_.isEmpty(canvasInfo.detail.locations)) {
          const mapped = _.map(canvasInfo.detail.locations, (item) => {
            return {
              uuid: item.uuid,
              address: item.address,
              area: item.area,
              areaUnit: item.areaUnit || "ft",
              chairs: item.chairAmount,
              city: item.city,
              coordinates: item.coordinates,
              country: item.country,
              state: item.state,
              stateShort: item.stateShort,
              countryShort: item.countryShort,
              employeeNumber: item.employeeAmount,
              employee: _.map(item.members, (member) => {
                return {
                  authority:
                    member &&
                    member.permission &&
                    member.permission.permission === "viewOnly"
                      ? 0
                      : 1,
                  firstName:
                    (member.memberUser && member.memberUser.firstName) || "",
                  lastName:
                    (member.memberUser && member.memberUser.lastName) || "",
                  id: member.memberUser.userId,
                  uuid: member.uuid,
                };
              }),
            };
          });
          const defaultLoc = mapped[0];
          const extraLoc = _.slice(mapped, 1);
          pageData = {
            ...pageData,
            defaultLocation: defaultLoc,
            extraLocation: extraLoc || [],
          };
        }
        plusAction(GetSelectionsAction);
        return pageData;
      }
      plusAction(GetSelectionsAction);
      return {
        slug: slug,
      };
    }
    plusAction(GetSelectionsAction);
    return {};
  },
};

export const GetSelectionsAction: ModuleAction<any, ModuleCompanyInfo> = {
  module: MODULE_COMPANY_INFO,
  process: async () => {
    const res = await getCustomized(true, ["category"]);
    if (res) {
      return {
        categoryOptions: res.category.business,
      };
    }
    return {};
  },
};

export const UpdateBaseInfoAction: ModuleAction<KV, ModuleCompanyInfo> = {
  module: MODULE_COMPANY_INFO,
  process: async (info: KV) => {
    return { ...info };
  },
};

export const DefaultLocationEditAction: ModuleAction<any, ModuleCompanyInfo> = {
  module: MODULE_COMPANY_INFO,
  process: async (location: LocationInfo, module: ModuleCompanyInfo) => {
    return { defaultLocation: location };
  },
};

export const UpdatePageInfoAction: ModuleAction<any, ModuleCompanyInfo> = {
  module: MODULE_COMPANY_INFO,
  process: async (options: KV, module: ModuleCompanyInfo) => {
    return { ...options };
  },
};

export const AddExtraLocationAction: ModuleAction<any, ModuleCompanyInfo> = {
  module: MODULE_COMPANY_INFO,
  process: async (key: any, module: ModuleCompanyInfo) => {
    return {
      extraLocation: [
        ...module.extraLocation,
        { uuid: uuidv4(), areaUnit: "ft" },
      ],
    };
  },
};

export const SearchLocationAction: ModuleAction<string, ModuleCompanyInfo> = {
  module: MODULE_COMPANY_INFO,
  process: async (keyword: string, module: ModuleCompanyInfo) => {
    return {};
  },
};

export const EditExtraLocationAction: ModuleAction<any, ModuleCompanyInfo> = {
  module: MODULE_COMPANY_INFO,
  process: async (location: LocationInfo, module: ModuleCompanyInfo) => {
    const oldList = [...module.extraLocation];
    const curLocation = _.find(oldList, { uuid: location.uuid });
    if (curLocation) {
      curLocation.uuid = location.uuid;
      curLocation.address = location.address;
      curLocation.chairs = location.chairs;
      curLocation.areaUnit = location.areaUnit;
      curLocation.area = location.area;
      curLocation.employee = location.employee;
      curLocation.employeeNumber = location.employeeNumber;
      return { extraLocation: oldList };
    }
    return {};
  },
};

export const DelExtraLocationAction: ModuleAction<any, ModuleCompanyInfo> = {
  module: MODULE_COMPANY_INFO,
  process: async (uuid: string, module: ModuleCompanyInfo) => {
    const newList = _.filter(module.extraLocation, (item) => {
      return item.uuid !== uuid;
    });
    return {
      extraLocation: newList,
    };
  },
};

export const DelEmployeeAction: ModuleAction<any, ModuleCompanyInfo> = {
  module: MODULE_COMPANY_INFO,
  process: async (params: KV, module: ModuleCompanyInfo) => {
    const { locationId, employeeId } = params;
    if (locationId === module.defaultLocation.uuid) {
      const newList = _.filter(module.defaultLocation.employee, (item) => {
        return item.id !== employeeId;
      });

      return {
        defaultLocation: { ...module.defaultLocation, employee: newList },
      };
    } else {
      const locations = [...module.extraLocation];
      const cur = _.find(locations, { uuid: locationId });
      if (cur) {
        const newList = _.filter(cur.employee, (item) => {
          return item.id !== employeeId;
        });
        cur.employee = newList;
        return { extraLocation: locations };
      } else {
        return {};
      }
    }
  },
};

export const SubmitAction: ModuleAction<any, ModuleCompanyInfo> = {
  module: MODULE_COMPANY_INFO,
  process: async (params: KV, module: ModuleCompanyInfo) => {
    const { history, isSkip, fromPost } = params;
    const locationList = [module.defaultLocation, ...module.extraLocation];
    const newLocationList = _.map(locationList, (item) => {
      let location = {
        uuid: uuidv4(), //item.uuid,
        employeeAmount: item.employeeNumber,
        chairAmount: item.chairs,
        area: item.area,
        areaUnit: item.areaUnit,
        address: item.address,
      } as any;
      if (!_.isEmpty(item.country)) {
        location = {
          ...location,
          country: item.country,
        };
      }
      if (!_.isEmpty(item.countryShort)) {
        location = {
          ...location,
          countryShort: item.countryShort,
        };
      }
      if (!_.isEmpty(item.state)) {
        location = {
          ...location,
          state: item.state,
        };
      }
      if (!_.isEmpty(item.stateShort)) {
        location = {
          ...location,
          stateShort: item.stateShort,
        };
      }
      if (!_.isEmpty(item.city)) {
        location = {
          ...location,
          city: item.city,
        };
      }
      if (!_.isEmpty(item.coordinates)) {
        location = {
          ...location,
          coordinates: item.coordinates,
        };
      }
      const newEmployee = _.map(item.employee, (emp) => {
        let newEmp = {
          uuid: emp.uuid || uuidv4(),
          permission: {
            permission: emp.authority === 0 ? "viewOnly" : "canEdit",
          },
          type: "employee",
        } as any;
        if (emp.id) {
          newEmp = {
            ...newEmp,
            memberUserId: emp.id,
          };
        } else {
          newEmp = {
            ...newEmp,
            email: emp.email,
          };
        }

        return newEmp;
      });
      location = {
        ...location,
        members: newEmployee,
      };
      return location;
    });
    const requestParams = {
      introduction: {
        name: module.companyName || null,
      },
      detail: {
        primaryContactNumber: module.contactNumber || null,
        scSalonId: module.scSalonId || null,
        categories: _.map(module.businessCategory, (item) => {
          let cateItem = {
            name: item.name,
          } as any;
          if (item.id) {
            cateItem = {
              ...cateItem,
              optionId: item.id,
            };
          }
          return cateItem;
        }),
        locations: newLocationList,
      },
    };
    let createRes;
    if (module.slug) {
      createRes = await editCanvas(module.id, requestParams);
    } else {
      createRes = await createCanvas(CanvasType.BUSINESS, requestParams);
    }
    if (createRes && createRes.slug && params.isDirect) {
      if (isSkip) {
        history.push(
          `/business-payment/${createRes.slug}?isPostOpportunity=true`
        );
      } else {
        history.push(
          `/business-canvas/${createRes.slug}${
            fromPost ? "?fromPost=true" : ""
          }`
        );
      }

      return { slug: createRes.slug };
    }
    return { slug: createRes.slug };
  },
};
