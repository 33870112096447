import React from 'react';
import { HomeVideoPreview } from './home_video_preview';

import profileProStudent from "../../../assets/images/home/profile-pro-student.png";
import iconCreate from "../../../assets/images/home/icons/icons_create.svg";
import iconEye from "../../../assets/images/home/icons/icons_eye.svg";
import iconResume from "../../../assets/images/home/icons/icons_resume.svg";
import iconQr from "../../../assets/images/home/icons/icons_qrcode.svg";
import iconClick from "../../../assets/images/home/icons/icons_click.svg";
import iconLand from "../../../assets/images/home/icons/icons_land.svg";
import levelUp from "../../../assets/images/home/level-up.png";
import { VidProps } from './home_business';

export class HomeProfessionals extends React.Component<VidProps, {}> {
  render() {
    const { setCurPlayUrl, videoUrl, ctaAction } = this.props;
    return <div className="cm-home-persona">
      <HomeVideoPreview
        imageSource={profileProStudent}
        setCurPlayUrl={setCurPlayUrl}
        videoUrl={videoUrl}
        ctaAction={ctaAction}
        imageAlt='Beauty professionals'
        headline='For Beauty Professionals &amp; Students To Live Out Their Dreams'
        ctaText='Create an online portfolio' />
      <div className="cm-home-info-section">
        <div className="cm-flex-column">
          <div>
            <h3>Create an Online Portfolio</h3>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconCreate} alt="create" />
              <p>Create a free standout portfolio that humanizes you and your skillset as an artist through video, imagery, and sync’s your Instagram feed all on one beautiful canvas.</p>
            </div>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconEye} alt="eye" />
              <p>Share your portfolio like a website and be seen on a national level.</p>
            </div>
          </div>
          <div>
            <h3>Auto Generate a Resume</h3>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconResume} alt="resume" />
              <p>Export your profile as a beautiful printed resume.</p>
            </div>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconQr} alt="QR code" />
              <p>Creates a QR code that links back to your porfolio.</p>
            </div>
          </div>
        </div>
        <div className="cm-flex-column">
          <div>
            <h3>Apply to Job Opportunities</h3>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconClick} alt="career" />
              <p>Showcase your portfolio and apply for job openings with a click of a button.</p>
            </div>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconLand} alt="alert" />
              <p> Land career opportunities like mentorships, brand educator roles, photo shoot campaigns, etc.</p>
            </div>
          </div>
          <img className="cm-home-persona__stats-block" src={levelUp} alt="opp status chart stats" />
        </div>
      </div>
    </div>;
  }
}
