import React, { useState } from "react";
import "./studentpro.less";
import StudentProHero from "./studentpro_hero";
import StudentProBrands from "./studentpro_brands";
import StudentProOpportunities from "./studentpro_opportunities";
import StudentProWatch from "./studentpro_watch";
import StudentProFeatured from "./studentpro_featured";
import { HomeFaq } from "../home/home_faq";
import StudentProHeadline from "./studentpro_headline";
import StudentProThrive from "./studentpro_thrive";
import VideoModal from "../../../components/component_video_modal";
import _ from "lodash";

const StudentProLanding: React.FC = () => {
  const [curPlayUrl, setCurPlayUrl] = useState("");

  return (
    <div className="cm-sp-landing">
      <StudentProHero setCurPlayUrl={setCurPlayUrl} source="adgirls" />
      <StudentProBrands />
      <StudentProOpportunities setCurPlayUrl={setCurPlayUrl} source="adgirls" />
      {/* <StudentProWatch setCurPlayUrl={setCurPlayUrl} source="adgirls" /> */}
      <StudentProFeatured source="adgirls" />
      <StudentProThrive source="adgirls" />
      <div className="cm-sp-faq">
        <StudentProHeadline headline="FAQ's" subtitle="Check out some of our frequently asked questions below." />
        <HomeFaq showSelector={false} showHeader={false} customBackground="#f2f3f3" />
      </div>
      {!_.isEmpty(curPlayUrl) && (
        <VideoModal
          visible
          onClose={() => setCurPlayUrl("")}
          url={curPlayUrl}
        />
      )}
    </div>
  );
};

export default StudentProLanding;
