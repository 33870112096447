import React from "react";
import { RouteComponentProps } from "react-router";
import { Breadcrumb, Button, message } from "antd";
import { ProfessionalItem } from "../../create_professional_canvas/professional_info";
import {
  IProfessioncalItem,
  professionalItem,
} from "../../../models/model_professioncal_info";
import { KV } from "module-reaction";
import IconAwardAdd from "../../../assets/images/ic_award_add.svg";
import { cloneDeep } from "lodash";
import "./upgrade_student_canvas.styl";
import { Link } from "react-router-dom";
import {
  router_my_canvases,
  router_professional_canvas_preview,
} from "../../../utils/enum";
import { getCustomized } from "../../../api/Options";
import { upgradeCanvasToPro } from "../../../api/Canvas";

interface UpgradeStudentCanvasProp extends RouteComponentProps {}

export class UpgradeStudentCanvas extends React.Component<
  UpgradeStudentCanvasProp,
  {
    professionalList: IProfessioncalItem[];
    categoryList: any[];
    stateIssueList: any[];
  } & KV
> {
  canvasId: any = undefined;
  state = {
    professionalList: [{ ...professionalItem }],
    categoryList: [],
    stateIssueList: [],
    isMobile: window.innerWidth <= 1024,
  };
  render() {
    const {
      professionalList,
      categoryList,
      stateIssueList,
      isMobile,
    } = this.state;

    return (
      <div className="info-container upgrade-student-canvas">
        <Breadcrumb>
          <Breadcrumb.Item key="canvases">
            <Link to={router_my_canvases}>My Canvases</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Update to pro</Breadcrumb.Item>
        </Breadcrumb>
        <div className="common-title">Your Professional Information</div>
        {professionalList.map((item: any, index: number) => (
          <ProfessionalItem
            key={index}
            proIndex={index}
            categoryList={categoryList}
            stateIssueList={stateIssueList}
            isMobile={isMobile}
            professionalList={professionalList}
            setListData={(value: any) => {
              this.setState({ professionalList: value });
            }}
            delListItem={(index: number) => {
              const { professionalList } = this.state;
              professionalList.splice(index, 1);
              this.setState({ professionalList });
            }}
            // user={}
            isEdit={true}
          />
        ))}
        <Button
          className="btn-secondary"
          style={{ marginBottom: "50px" }}
          onClick={() => {
            const { professionalList } = this.state;
            const newList = [...professionalList, cloneDeep(professionalItem)];

            this.setState({ professionalList: newList });
          }}
        >
          <img src={IconAwardAdd} alt="" />
          Add more categories
        </Button>
        <p>
          I understand that my student account may include educational records and that upgrading to a professional account and clicking on the upgrade button below constitutes my consent to the release of all such records to Canvas ME who may use and share this information as described in its Privacy Policy.  
          I also confirm that I have read and agree to be bound by Canvas ME’s <a href="/terms" target="_blank">Terms and Conditions.</a>
        </p>
        <Button className="btn-primary btn-submit" onClick={this.onSubmit}>
          Confirm to update
        </Button>
      </div>
    );
  }

  componentDidMount() {
    const { location } = this.props;
    if (!location.search) {
      return;
    }
    const id = new URLSearchParams(location.search).get("id");
    this.canvasId = id;

    window.onresize = () => {
      this.setState({ isMobile: window.innerWidth < 1024 });
    };

    this.fetchOptions();
  }

  private fetchOptions = async () => {
    const res = await getCustomized(true, ["category", "state"]);
    if (!res) {
      return;
    }
    const categoryList = res.category.professional.concat({
      id: 0,
      name: "Other",
    });
    const stateIssueList = res.state;

    this.setState({ stateIssueList, categoryList });
  };

  private onSubmit = async (e: any) => {
    const { professionalList } = this.state;
    const one = professionalList[0];
    if (!(one.id !== -1 || one.otherName)) {
      message.error("please type at least one category");
      return;
    }
    const categories = professionalList.map((item) => {
      const it: any = { ...item };
      delete it.id;
      delete it.otherName;
      if (item.id !== -1) {
        it.optionId = item.id;
      }
      it.name = item.name || item.otherName;

      return it;
    });
    const res = await upgradeCanvasToPro(this.canvasId, { categories });
    if (res?.success) {
      this.props.history.push(
        router_professional_canvas_preview.replace(":id", this.canvasId)
      );
    }
  };
}
