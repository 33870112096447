import React from "react";
import { getStartedViaSource, getStaticAssetPath } from "../../../utils/utils";
import { Button } from "antd";
import { global_router } from "../../../routers";
import { router_get_started } from "../../../utils/enum";

interface Props {
    name: string;
    location?: string;
    image: string;
    type: string;
    url: string;
    visualization: 'canvas' | 'opportunity'
  }

const CanvasTile: React.FC<Props> = (props) => {
    const { image, name, location, type, url, visualization = 'canvas' } = props;
    const openPortfolio = () => {
      window.location.href = url;
    }
    const displayName = visualization === 'canvas' ? name : name.toUpperCase();
    const actionLabel = visualization === 'canvas' ? 'Launch Portfolio' : 'View Opportunity';
    
    return (
      <div className="cm-sp-canvas-tile cm-flex-column" onClick={() => openPortfolio()}>
        <div className="cm-flex-1">
          <div className="cm-sp-canvas-tile-img" style={{ backgroundImage: `url(${image})` }} />
        </div>
        <div className="cm-sp-canvas-tile__footer">
          <h4>{displayName}</h4>
          {visualization === 'canvas' && <h5>{location}</h5>}
        </div>
        <div className="cm-sp-canvas-tile-overlay">{actionLabel}</div>
        {visualization === 'canvas' && <div className="cm-sp-canvas-tile-type">{type}</div>}
        {visualization === 'opportunity' && <div className="cm-sp-canvas-tile-type--opportunity">{type}</div>}
        <img className="cm-sp-canvas-ribbon" src={getStaticAssetPath(`studentpro-landing/symbol-with-background.png`)} alt="Canvas ME symbol" />
      </div>
    )
  };

const FeaturedOpportunities: React.FC = () => {
  const portfolios = [
    {
      name: "Assistant",      
      image: "assistant.png",
      type: "Full time",
      url: "https://www.canvasme.com/opportunity-detail/elle-b-salon-assistant"
    },
    {
      name: "Front Desk",
      image: "frontdesk.png",
      type: "Full time",
      url: "https://www.canvasme.com/opportunity-detail/elle-b-salon-front-desk"
    },
    {
      name: "Hairstylist",
      image: "hairstylist.png",
      type: "Full time",
      url: "https://www.canvasme.com/opportunity-detail/elle-b-salon-hairstylist"
    },
    {
      name: "Educator",
      image: "educator.png",
      type: "Full time",
      url: "https://www.canvasme.com/opportunity-detail/redken-redken-educator"
    },
  ];

  return (
    <div>
        <div className="cm-sp-headline">
            <img src={getStaticAssetPath('studentpro-landing/canvas-symbol.png')} alt="Canvas symbol" />
            <div>
                <h1>Featured Career Opportunities.</h1>
                <p>Canvas ME empowers you to overcome your biggest hiring challenges in the beauty industry. Build, share, and manage your career opportunity page in seconds. Vet candidates through portfolios, not resumes, and cut your interview process in half.</p>
            </div>
        </div>
      <div className="cm-flex-row cm-flex-wrap cm-flex-space">
        { portfolios.map(p => <CanvasTile visualization='opportunity' name={p.name} image={getStaticAssetPath(`studentpro-landing/portfolios/${p.image}`)} type={p.type} url={p.url} /> )}
      </div>
      <div className="cm-flex-row cm-flex-center cm-v-spacing">
        <Button className="btn-primary" onClick={() => global_router.history.push(`${router_get_started}`)}>Join in less than 3 minutes</Button>
      </div>
    </div>
  )
};

export default FeaturedOpportunities;
