import React, { useEffect, useState } from "react";
import { SourceName, TargetType } from "../../utils/enum";
import "./join_choice.less";
import IconCrown from "../../assets/images/ic_crown.svg";
import IconHeart from "../../assets/images/ic_heart.svg";
import IconRadioOff from "../../assets/images/ic_radio_off.svg";
import IconRadioOn from "../../assets/images/ic_radio_on.svg";
import store from '../../utils/store';


interface Props {
  selectItem: any;
}

export function JoinChoiceComponent(props: any) {
  const { selectItem } = props;
  const [selectedOption, setSelectedOption] = useState('');

  const urlParams = new URLSearchParams(window.location.search);
  const source = urlParams.get('source');
  const storeValue = store.getState().landingPage.visitedLandingPage

  useEffect(() => {
    if(source == SourceName.WELLA || storeValue.source == SourceName.WELLA){
      selectItem(TargetType.BUSINESS)
    }
  }, []);

  function nextButton() {
    selectItem(selectedOption)
  }

  return (
    <div className="cm-join-choice-root">
      <p className="title">Choose how to get Started with Canvas ME:</p>
      <div className="join-list">
        <div className="join-option" onClick={() => setSelectedOption(TargetType.PERSONAL)}>
          <img className="icon-type" src={IconHeart} alt="heart" />
          <span className="canvas-type">Create your Canvas ME portfolio</span>
          <div className="free-box">
            <span className="free">FREE</span>
          </div>
          <img className="ellipse" src={selectedOption == TargetType.PERSONAL ? IconRadioOn : IconRadioOff} alt="Option"></img>
        </div>
        {/* <ul className={`product-description ${selectedOption == TargetType.PERSONAL ? "show-description" : "hide-description"}`}>
            <li>
              Share your portfolio like a website and be seen on a national
              level.
            </li>
            <li>
              Apply for opportunities with a click of a button.
            </li>
            <li>
              Export your portfolio as a beautiful printed resume.
            </li>
            <li>
              Exclusive mentorships, brand educator, photoshoot campaigns, and
              more.
            </li>
          </ul> */}
        <div className="join-option" onClick={() => setSelectedOption(TargetType.BUSINESS)}>
          <img className="icon-type" src={IconCrown} />
          <span className="canvas-type">Purchase a Career Opportunity</span>
          <div className="premium-box">
            <span className="premium">PREMIUM</span>
          </div>
          <img className="ellipse" src={selectedOption == TargetType.BUSINESS ? IconRadioOn : IconRadioOff} alt="option"></img>
        </div>
        {/* <ul className={`product-description ${selectedOption == TargetType.BUSINESS ? "show-description" : "hide-description"}`}>
            <li>
              Create career opportunity pages that humanize your brand through
              video and imagery.
            </li>
            <li>
              Set statuses on candidates and keep track of all your hiring
              efforts.
            </li>
            <li>
              Your opportunity is sharable and automatically alerts all members
              weekly.
            </li>
          </ul> */}
        <div className="join-option" onClick={() => setSelectedOption(TargetType.SCHOOL)}>
          <img className="icon-type" src={IconCrown} alt="crown"/>
          <span className="canvas-type">Become a Canvas ME School</span>
          <div className="premium-box">
            <span className="premium">PREMIUM</span>
          </div>
          <img className="ellipse" src={selectedOption == TargetType.SCHOOL ? IconRadioOn : IconRadioOff} alt="option"></img>
        </div>
        {/* <ul className={`product-description ${selectedOption == TargetType.SCHOOL ? "show-description" : "hide-description"}`}>
            <li>
              Leverage Canvas ME portfolios for Placement Documentation.
            </li>
            <li>
              Access for Students to build digital portfolios versus Word doc
              resumes.
            </li>
            <li>
              Track students &amp; alumni through your dashboard.
            </li>
          </ul> */}
        <button className="btn-primary next-button" onClick={nextButton}>NEXT TO STEP 2</button>
      </div>
    </div>
  );
}
