import React from 'react';
import { message } from "antd";
import { mapProp, doAction, doFunction, getModuleProp } from "module-reaction";

import NewHomeHero from './new_home_hero';
import { HomeLogos } from './home_logos';
import { HomeOvercome } from './home_overcome';
import HomeStats from './home_stats';
import { HomeBusiness } from './home_business';
import { HomeProfessionals } from './home_professionals';
import { HomeSchools } from './home_schools';
import { HomeTestimonials } from './home_testimonials';
import { HomeMembership } from './home_membership';
import { HomeCreate } from './home_create';
import { HomePress } from './home_press';
import { HomeFaq } from './home_faq';

import './home.less';
import { ModuleHomepage, module_homepage } from '../../../models/model_homepage';
import VideoModal from '../../../components/component_video_modal';
import { scBaseUrl, publicSourceUrl } from '../../../utils/constants';
import { searchMyCanvases, getCanvas } from "../../../api/Canvas";
import { module_post_job_opp, MODULE_POST_JOB_OPP } from '../../../models/model_post_job_opp';
import { router_company_info, router_dashboard_post_job_opp, router_get_started, router_my_canvases, TargetType } from '../../../utils/enum';
import { MODULE_USER } from '../../../models/model_user';
import { getToken } from '../../../utils/authStorage';
import FeaturedPortfolios from './home_featured_portfolios';
import FeaturedOpportunities from './home_featured_opps';

const vidPathPro = scBaseUrl + 'Canvas_Video_Pro_v3.mp4';
const vidPathSchool = scBaseUrl + 'Canvas-School.mp4';
const vidPathBusiness = publicSourceUrl + 'Canvas_Business_Owner_v2.mp4';

@mapProp(module_homepage)
export class Home extends React.Component<ModuleHomepage, {}> {
  state = {
    curPlayUrl: '',
    loading: false
  }

  setCurPlayUrl = (url: string) => {
    this.setState({ curPlayUrl: url });
  }

  checkCanvasAndPost = async (type: string) => {
    const { history } = this.props;
    this.setState({ loading: true });
    const canvasList = await searchMyCanvases(type, "all");
    this.setState({ loading: false });
    if (canvasList && canvasList.list && canvasList.list.length > 0) {
      const first = canvasList.list[0];
      if (first && first.id) {
        const res = await getCanvas(first.id);
        if (res && res.job && res.job.max <= res.job.now) {
          message.error(
            "Your current membership and plan does not cover this opportunity post. Please check your membership setting or contact support at hello@canvasme.com"
          );
          return;
        }
        doAction(MODULE_POST_JOB_OPP, module_post_job_opp);
        doFunction(async () => {
          history.push({
            pathname: router_dashboard_post_job_opp,
            search: `?id=${first.id}&isInMyAccount=1`,
          });
        });
      }
    } else {
      history.push({
        pathname: router_company_info,
        search: `?fromPost=true`,
      });
    }
  }

  onPostOpportunity = () => {
    const { history } = this.props;
    const curUser = getModuleProp(MODULE_USER, 'curUser');
    if (curUser && curUser.profile && curUser.profile.target) {
      switch (curUser.profile.target) {
        case TargetType.BUSINESS:
        case TargetType.CAREER:
          this.checkCanvasAndPost("business");
          break;
        case TargetType.SCHOOL:
          this.checkCanvasAndPost("school");
          break;
        default:
          history.push({
            pathname: router_company_info,
            search: `?fromPost=true`,
          });
          break;
      }
    } else {
      history.push("/get-started");
    }
  }

  onCreateCanvas = () => {
    const { history } = this.props;
    const createCanvasUrl = !getToken() ? router_get_started : router_my_canvases;
    history.push(createCanvasUrl);
  }

  render(): React.ReactNode {
    const { history } = this.props;
    const { curPlayUrl } = this.state;
    return <div className="cm-home">
      <NewHomeHero />
      <HomeLogos />
      <HomeStats />
      <FeaturedPortfolios />
      <HomeOvercome />
      <FeaturedOpportunities />
      <HomeCreate createCanvas={this.onCreateCanvas} postOpportunity={this.onPostOpportunity} />
      <HomeBusiness setCurPlayUrl={this.setCurPlayUrl} videoUrl={vidPathBusiness} ctaAction={this.onPostOpportunity} />
      <HomeProfessionals setCurPlayUrl={this.setCurPlayUrl} videoUrl={vidPathPro} ctaAction={this.onCreateCanvas} />
      <HomeSchools setCurPlayUrl={this.setCurPlayUrl} videoUrl={vidPathSchool} ctaAction={this.onCreateCanvas} />
      <HomeTestimonials />
      <HomeMembership />
      <HomePress />
      <HomeFaq />
      {!!curPlayUrl && (
        <VideoModal
          visible
          onClose={() => this.setCurPlayUrl("")}
          url={curPlayUrl}
        />
      )}
    </div>;
  }
}
