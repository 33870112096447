import React, { useEffect } from 'react';
import "./home_stats.less";

import iconBadge from "../../../assets/images/home/icons/icons_badge.svg";
import iconSchool from "../../../assets/images/home/icons/icons_school.svg";
import iconEducators from "../../../assets/images/home/icons/icons_educators.svg";
import { Accounts, LiveUserCount } from '../../../utils/constants';

const HomeStats = () => {
  const [userCount, setUserCount] = React.useState(Accounts.USERS)

  const fetchAndSetUserCount = async () => {
    const countString = await LiveUserCount();
    setUserCount(countString);
  }

  useEffect(() => {
    fetchAndSetUserCount();
  }, [])

  return (
    <div className="cm-home-stats cm-wave-container cm-flex-column cm-flex-center">
      <h2 className="cm-gutter-bottom-lg">Join a thriving community with proven success</h2>
      <div className="cm-flex-responsive-row cm-flex-space">
        <div className="cm-home-stats__item cm-flex-column cm-flex-v-center">
          <img className="cm-home__icon" src={ iconBadge } alt="trophy" />
          <h1 className="jumbo">{userCount}</h1>
          <p>Beauty, barber, esthi, nails, makeup, students, pros, schools, brands and businesses looking to find their people.</p>
        </div>
        <div className="cm-home-rule-v"></div>
        <div className="cm-home-stats__item cm-flex-column cm-flex-v-center">
          <img className="cm-home__icon" src={ iconSchool } alt="trophy" />
          <h1 className="jumbo">{Accounts.SCHOOLS}+</h1>
          <p>Beauty schools joining on Canvas ME</p>
        </div>
        <div className="cm-home-rule-v"></div>
        <div className="cm-home-stats__item cm-flex-column cm-flex-v-center">
          <img className="cm-home__icon" src={ iconEducators } alt="trophy" />
          <h1 className="jumbo">100+</h1>
          <p>National educators hired in less than 60 days by Pulp Riot, Mizani, and Purelogy</p>
        </div>
      </div>
    </div>
  );
}

export default HomeStats;
