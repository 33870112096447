import React from 'react';
import './home_selector.less';

import caretDown from "../../../assets/images/home/caret-down.svg";
import { Dropdown, Menu } from 'antd';

interface Props {
  selectedItem?: any;
  items?: any[];
  placeholderText: string;
  itemSelected?: any;
}

export class HomeSelector extends React.Component<Props, {}> {
  renderMenu = () => {
    const { items, itemSelected } = this.props;
    return <Menu selectable={ true } onSelect={itemSelected}>
      {items && items.map((item: any) => (
        <Menu.Item key={item.name}>{item.name}</Menu.Item>
      ))}
    </Menu>
  }
  render() {
    const { selectedItem, placeholderText } = this.props;
    return <div className="cm-home-selector cm-flex-row cm-flex-center">
      <Dropdown overlay={this.renderMenu}>
        <div className="cm-home-selector__inner">
          {(!selectedItem || !selectedItem.name ) && <p>{placeholderText}</p>}
          {selectedItem && selectedItem.name && <p>{selectedItem.name}</p>}
          <img src={caretDown} alt="caret down" />
        </div>
      </Dropdown>
    </div>;
  }
}
