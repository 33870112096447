import { Button } from 'antd';
import React from 'react';
import { HomeSelector } from './home_selector';
import './home_create.less';

export interface Props {
  createCanvas: any;
  postOpportunity: any;
}

export class HomeCreate extends React.Component<Props, {}> {
  ctaCreateCanvas = () => {
    const { createCanvas } = this.props;
    createCanvas();
  }

  ctaPostOpportunity = () => {
    const { postOpportunity } = this.props;
    postOpportunity();
  }

  state = {
    selectedRole: null,
    roleItems: [
      {
        id: 'student',
        name: 'I am a student',
        actionIds: ['job', 'mentorship', 'scholarship', 'portfolio']
      },
      {
        id: 'professional',
        name: 'I am a beauty/barber professional',
        actionIds: ['job', 'mentorship', 'portfolio']
      },
      {
        id: 'owner',
        name: 'I am a business owner',
        actionIds: ['market']
      },
      {
        id: 'school',
        name: 'I am a teacher / school leadership',
        actionIds: ['promote']
      },
      // {
      //   id: 'sales',
      //   name: 'I am a sales rep'
      // },
      {
        id: 'nonlicensed',
        name: 'I am a non licensed professional',
        actionIds: ['job', 'mentorship', 'portfolio']
      }
    ],
    selectedAction: { ctaText: 'Create an online portfolio', ctaAction: this.ctaCreateCanvas },
    visibleActions: [{ ctaText: '', ctaAction: null }],
    actionItems: [
      {
        id: 'job',
        name: 'Ready to find the job of my dreams',
        ctaAction: this.ctaCreateCanvas,
        ctaText: 'Create an online portfolio'
      },
      {
        id: 'mentorship',
        name: 'Ready to find an inspiring mentorship',
        ctaAction: this.ctaCreateCanvas,
        ctaText: 'Find a mentorship'
      },
      {
        id: 'scholarship',
        name: 'Ready to find a life changing scholarship',
        ctaAction: this.ctaCreateCanvas,
        ctaText: 'Find a scholarship'
      },
      {
        id: 'promote',
        name: 'Ready to promote/market and track my students',
        ctaAction: this.ctaCreateCanvas,
        ctaText: 'Become a Canvas ME school'
      },
      {
        id: 'market',
        name: 'Ready to post and market my career opportunity',
        ctaAction: this.ctaPostOpportunity,
        ctaText: 'Post a job opportunity'
      },
      {
        id: 'portfolio',
        name: 'Ready to create my online portfolio',
        ctaAction: this.ctaCreateCanvas,
        ctaText: 'Create an online portfolio'
      },
      {
        id: 'clients',
        name: 'Ready to promote this to my amazing clients',
        ctaAction: this.ctaCreateCanvas,
        ctaText: 'Promote to clients'
      }
    ]
  }

  roleSelected = (item: any) => {
    const { roleItems } = this.state;
    const role = roleItems.find(ri => ri.name === item?.item?.props?.children);
    const { actionItems } = this.state;
    const actionsForRole = actionItems.filter(ai => role && role.actionIds && role.actionIds.indexOf(ai.id) > -1);
    this.setState({ selectedRole: role, visibleActions: actionsForRole, selectedAction: null });
  }

  actionSelected = (item: any) => {
    const { actionItems } = this.state;
    const action = actionItems.find(ai => ai.name === item?.item?.props?.children);
    this.setState({ selectedAction: action });
  }

  render() {
    const { roleItems, selectedRole, visibleActions, selectedAction } = this.state;
    return <div className="cm-home-create cm-wave-container cm-flex-column cm-flex-center">
      <h2 className="cm-gutter-bottom-lg">Live out the beauty career of your dreams!</h2>
      <div className="cm-flex-responsive-row cm-flex-space">
        <HomeSelector placeholderText='I am a...' itemSelected={this.roleSelected} items={roleItems} selectedItem={selectedRole} />
        <HomeSelector placeholderText='Ready to...' itemSelected={this.actionSelected} items={visibleActions} selectedItem={selectedAction} />
      </div>
      {selectedAction && <Button className='btn-primary' onClick={() => selectedAction.ctaAction()}>{ selectedAction.ctaText }</Button>}
    </div>;
  }
}
