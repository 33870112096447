import React from 'react';
import { HomeVideoPreview } from './home_video_preview';

import profileBusiness from "../../../assets/images/home/profile-business.png";
import iconCareer from "../../../assets/images/home/icons/icons_career.svg";
import iconAlert from "../../../assets/images/home/icons/icons_alerts.svg";
import iconBrowse from "../../../assets/images/home/icons/icons_browse.svg";
import iconFilter from "../../../assets/images/home/icons/icons_filter.svg";
import iconCandidates from "../../../assets/images/home/icons/icons_candidates.svg";
import iconMessage from "../../../assets/images/home/icons/icons_message.svg";
import oppStatusChart from "../../../assets/images/home/opp-status-chart-stats.png";

export interface VidProps {
  setCurPlayUrl: any;
  videoUrl: string;
  ctaAction?: Function;
}

export class HomeBusiness extends React.Component<VidProps, {}> {
  render() {
    const { setCurPlayUrl, videoUrl, ctaAction } = this.props;
    return <div className="cm-home-persona">
      <HomeVideoPreview
        imageSource={profileBusiness}
        setCurPlayUrl={setCurPlayUrl}
        videoUrl={videoUrl}
        ctaAction={ctaAction}
        imageAlt='Beauty business'
        headline='For Beauty Businesses To Hire The Right Fit'
        ctaText='Post a job opportunity' />
      <div className="cm-home-info-section">
        <div className="cm-flex-column">
          <div>
            <h3>Career Opportunities</h3>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconCareer} alt="career" />
              <p>Create career opportunity pages that humanize your brand and culture through video and imagery.</p>
            </div>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconAlert} alt="alert" />
              <p>Your opportunity is sharable and automatically alerts all members weekly.</p>
            </div>
          </div>
          <div>
            <h3>Attract Top Talent</h3>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconBrowse} alt="career" />
              <p> Easily vet candidates by browsing their Canvas ME portfolios.</p>
            </div>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconFilter} alt="alert" />
              <p>Filter candidates based on KPI’s, brands they haveused, years of experience, and more.</p>
            </div>
          </div>
        </div>
        <div className="cm-flex-column">
          <div>
            <h3>Track &amp; Monitor Applicants</h3>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconCandidates} alt="career" />
              <p>Set statuses on candidates and keep track of all your hiring efforts.</p>
            </div>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconMessage} alt="alert" />
              <p>Connect and message candidates for current or future opportunities.</p>
            </div>
          </div>
          <img className="cm-home-persona__stats-block" src={oppStatusChart} alt="opp status chart stats" />
        </div>
      </div>
    </div>;
  }
}
