import React from 'react';

import './home_press.less';

import press from "../../../assets/images/home/canvas-me-founders-jen-matt-martinelli.png";

export class HomePress extends React.Component<{}, {}> {
  state = {
    pressItems: [
      {
        date: "Jan 15, 2021",
        source: "SalonCentric",
        title: "How To Create A Killer Bio For Your Digital Porfolio",
        link: "https://www.saloncentric.com/learn/categories/business/education/ch-how-to-create-a-killer-bio-for-your-digital-porfolio.html"
      },
      {
        date: "Jan 19, 2021",
        source: "Grow My Salon Business",
        title: "Utilising Technology For Better Recruitment with Jen & Matt Martinelli",
        link: "https://growmysalonbusiness.com/podcast/073/"
      },
      {
        date: "Jul 19, 2021",
        source: "Ask the Color Expert",
        title: "The Ultimate Salon Recruiting Platform with Jen & Matt Martinell",
        link: "https://anchor.fm/elaine-travis/episodes/The-ultimate-salon-recruiting-platform-Canvas-Me---Jen-and-Matt-Martinell-e14n1q5/a-a4rrrfr"
      }
    ]
  }
  render() {
    const { pressItems } = this.state;
    return <div className="cm-home-press cm-home__section cm-flex-center cm-flex-column">
      <h2 className="cm-gutter-bottom-xxl">
        Canvas ME Press
      </h2>
      <div className="cm-flex-responsive-row cm-flex-center">
        <img className="cm-home-press__pic" src={press} alt="Jen and Matt" />
        <div>
          { pressItems && pressItems.map(pi => (
            <a className="cm-home-press__item" href={pi.link} target="_blank" rel="noopener noreferrer">
              <h6>{pi.date} | {pi.source}</h6>
              <h3>{pi.title}</h3>
            </a>
          ))}
        </div>
      </div>
    </div>;
  }
}
