import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./component_admin_subscription_form.less";
import { toNumber } from "lodash";
import { setupExternalPayment } from "../api/Subscription";
import { Spin, notification } from "antd";

type AdminSubscriptionFormProps = {
  canvasId: number;
  canvasName?: string;
};

function AdminSubscriptionForm({
  canvasId,
  canvasName = null,
}: AdminSubscriptionFormProps) {
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [numberOfMonths, setNumberOfMonths] = useState(null);
  const [numberOfOpps, setNumberOfOpps] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [notes, setNotes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const isSelected = (value: string) => subscriptionType === value;
  const history = useHistory();

  const changeSubscriptionType = (value: string) => {
    if (value === "complimentary") {
      setPaymentType(null);
      setPaymentAmount(null);
    }
    setSubscriptionType(value);
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setLoading(true);
    const data = {
      canvasId,
      subscriptionType,
      numberOfMonths,
      numberOfOpps,
      paymentType,
      paymentAmount,
      notes,
    };

    console.log(data);

    try {
      const response = await setupExternalPayment(data);
      notification.success({
        message: "Success",
        description: "Successfully added subscription",
      });
      
      const fromAdmin = window.location.pathname === '/admin-external';
      
      history.push('/my-canvases');
      if (fromAdmin) {
        setTimeout(() => {
          history.push('/admin-external');
        }, 0);
      }
    
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const offlineFields = () => {
    return (
      <>
        <label>
          <span>Payment Type:</span>
          <input
            type="text"
            value={paymentType}
            onChange={(e) => setPaymentType(e.target.value)}
            maxLength={50}
          />
        </label>
        <label>
          <span>Payment Amount:</span>
          <input
            type="number"
            value={paymentAmount}
            onChange={(e) => {
              const value = e.target.value;
              setPaymentAmount(value ? toNumber(value) : null);
            }}
          />
        </label>
        {paymentAmount > 0 && (
          <span className="payment-amount">
            $
            {new Intl.NumberFormat("en-US", {
              style: "decimal",
              minimumFractionDigits: 2,
            }).format(paymentAmount / 100)}
          </span>
        )}
      </>
    );
  };

  const pointsFields = () => {
    return (
      <>
        <label>
          <span>Point Type</span>
          <input
            type="text"
            value={paymentType}
            onChange={(e) => setPaymentType(e.target.value)}
            maxLength={50}
          />
        </label>
        <label>
          <span>Point Value</span>
          <input
            type="number"
            value={paymentAmount}
            onChange={(e) => setPaymentAmount(e.target.value)}
            maxLength={50}
          />
        </label>
        {paymentAmount > 0 && (
          <span className="payment-amount">
            $
            {new Intl.NumberFormat("en-US", {
              style: "decimal",
              minimumFractionDigits: 2,
            }).format(paymentAmount / 100)}
          </span>
        )}
      </>
    );
  };

  const complimentaryFields = () => {
    return (
      // TODO: Add Free Reason field or just use notes?
      <>
        <label>
          <span>Free Reason</span>
        </label>
      </>
    );
  };

  return (
    <div className="admin-sub-form">
      {loading ? (
        <>
          <Spin />
        </>
      ) : (
        <>
          {error && (
            <>
              <div className="error">{error}</div>
            </>
          )}

          {canvasName && <h2>Add External Payment to {canvasName} </h2>}
          <form onSubmit={handleSubmit}>
            <div className="radio-group">
              <label
                className={`radio-button ${
                  isSelected("offline") ? "selected" : ""
                }`}
              >
                <input
                  type="radio"
                  value="offline"
                  checked={isSelected("offline")}
                  onChange={(e) => changeSubscriptionType(e.target.value)}
                  className="hidden-radio"
                />
                Offline Payment 💵
              </label>

              <label
                className={`radio-button ${
                  isSelected("points") ? "selected" : ""
                }`}
              >
                <input
                  type="radio"
                  value="points"
                  checked={isSelected("points")}
                  onChange={(e) => changeSubscriptionType(e.target.value)}
                  className="hidden-radio"
                />
                Redeem Points ✨
              </label>

              <label
                className={`radio-button ${
                  isSelected("complimentary") ? "selected" : ""
                }`}
              >
                <input
                  type="radio"
                  value="complimentary"
                  checked={isSelected("complimentary")}
                  onChange={(e) => changeSubscriptionType(e.target.value)}
                  className="hidden-radio"
                />
                Complimentary Subscription 🎁
              </label>
            </div>

            {subscriptionType && (
              <>
                <div className="inputs">
                  {subscriptionType === "offline" && offlineFields()}
                  {subscriptionType === "points" && pointsFields()}
                  <label>
                    <span>Number of Months:</span>
                    <input
                      type="number"
                      value={numberOfMonths}
                      onChange={(e) => {
                        const value = e.target.value;
                        setNumberOfMonths(value ? toNumber(value) : null);
                      }}
                      required
                    />
                  </label>
                  <label>
                    <span>Number of Opportunities:</span>
                    <input
                      type="number"
                      value={numberOfOpps}
                      onChange={(e) =>
                        setNumberOfOpps(toNumber(e.target.value))
                      }
                      required
                    />
                  </label>
                </div>
                <div className="notes">
                  <label>
                    Notes:
                    <textarea
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      maxLength={500}
                    />
                    {notes && <> {notes.length} / 500 characters used </>}
                  </label>
                </div>
                <div className="submit">
                  <button type="submit">Submit</button>
                </div>
              </>
            )}
          </form>
        </>
      )}
    </div>
  );
}

export default AdminSubscriptionForm;
