import React, { useEffect } from "react";
import StudentProHeadline from "./studentpro_headline";
import StudentProCanvasTile from "./studentpro_canvas_tile";
import { getStartedViaSource, getStaticAssetPath } from "../../../utils/utils";
import { Button } from "antd";
import { Accounts, LiveUserCount } from "../../../utils/constants";

interface Props {
  ctaLabel?: string;
  source: string;
}

const StudentProFeatured: React.FC<Props> = ({ ctaLabel = 'Join in less than 3 minutes', source }) => {
  const [userCount, setUserCount] = React.useState(Accounts.USERS)

  const fetchAndSetUserCount = async () => {
    const countString = await LiveUserCount();
    setUserCount(countString);
  }

  useEffect(() => {
    fetchAndSetUserCount();
  }, [])
  
  const portfolios = [
    {
      name: "Lauren",
      location: "West Jordan, UT",
      image: "lauren.png",
      type: "cosmo",
      url: "https://www.canvasme.com/canvas/lauren-jones"
    },
    {
      name: "Telaysia",
      location: "Tampa, FL",
      image: "telaysia.png",
      type: "esthi",
      url: "https://www.canvasme.com/canvas/telaysia-allen"
    },
    {
      name: "Erick",
      location: "Boston, MA",
      image: "erick.png",
      type: "makeup",
      url: "https://www.canvasme.com/canvas/erick-vargas"
    },
    {
      name: "Rashleigh",
      location: "Arlington, TX",
      image: "rashleigh.png",
      type: "barber",
      url: "https://www.canvasme.com/canvas/rashleigh-beaton"
    },
  ];

  return (
    <div>
      <StudentProHeadline headline="Featured Canvas Portfolios." subtitle={`Join a network of ${userCount} beauty barber, esthi, nails, makeup, students, pros, schools, brands and businesses looking to find their people.`} />
      <div className="cm-flex-row cm-flex-wrap cm-flex-space">
        { portfolios.map(p => <StudentProCanvasTile visualization="canvas" name={p.name} location={p.location} image={getStaticAssetPath(`studentpro-landing/portfolios/${p.image}`)} type={p.type} url={p.url} /> )}
      </div>
      <div className="cm-flex-row cm-flex-center cm-v-spacing">
        <Button className="btn-primary" onClick={() => getStartedViaSource(source)}>{ctaLabel}</Button>
      </div>
    </div>
  )
};

export default StudentProFeatured;
