import React from "react";
import { RouteComponentProps } from "react-router";
import BannerImg from '../../assets/images/homepage/about_us_banner.png'
import IconPlay from "../../assets/images/homepage/ic_play.svg";
import ContentImg from '../../assets/images/homepage/about.jpg'
import './homepage_about.less'
import VideoModal from "../../components/component_video_modal";

export class HomepageAbout extends React.Component<RouteComponentProps> {
  state = {
    playing: false
  }

  playVideo = () => {
    this.setState({ playing: true})
  }
  closeVideo = () => {
    this.setState( { playing: false })
  }
  render() {
    return (
      <div className="homepage-about-wrap">
        <div className="about-banner">
          <div className="max-content">
            <div className="layout">
              <div className="homepage-title-wrap">
                <div className="guide">About us</div>
                <div className="title">We empower beauty professionals so that they can live out the career of their dreams.</div>
              </div>
            </div>
            <img className="banner-img" src={BannerImg} alt="" />
          </div>
        </div>
        <div className="about-content">
          <div className="padding-layout flex-between-panel">
            <div className="content-img">
            <img className='about-img' src={ContentImg} alt="" />
            <div className="icon-play" onClick={this.playVideo}>
                <img src={IconPlay} alt="" />
              </div>
            </div>
            <div className="homepage-title-wrap">
              <div className="title">Why Canvas ME</div>
              <div className="intro">
                At Canvas ME, we come to work everyday to solve one of the biggest problems within the beauty industry. Everyone is unsure. Salon owners and managers don’t know where to go to find the right talent who align with their mission, vision, and culture. Beauty professionals don’t have a designated space to showcase their craft and value, and most cosmetology students don’t have a clear path once they graduate.
              <br />
              <br />
              Our mission at Canvas ME is to create a platform for beauty professionals that provides them with the ability to create beautiful portfolios, to grow, and to network with the beauty industry’s best artists. With a major focus on business education, networking, and emotional intelligence we’re building a space that beauty professionals deserve: a platform that truly represents the industry and provides the tools needed to be smarter, stronger, and more determined than ever to live your best life.
            </div>
            </div>
          </div>
        </div>
        <VideoModal className='content-play-modal' visible={this.state.playing} onClose={this.closeVideo} url='https://www.youtube.com/watch?v=OKz7wCk7_XQ&feature=youtu.be' />
      </div>
    )
  }
}