import { Button } from 'antd';
import React from 'react';
import { FaqExpander } from './faq_expander';

import './home_faq.less';

interface Props {
  showSelector?: boolean;
  customBackground?: string;
  showHeader?: boolean;
  includeSchoolFaqs?: boolean;
}

export class HomeFaq extends React.Component<Props, {}> {
  static defaultProps = {
    showSelector: true,
    showHeader: true,
    includeSchoolFaqs: true
  };

  state = {
    selectedQuestionSet: 'prostudent',
    proStudentQuestions: [
      {
        question: "1. Is Canvas ME just for the professional beauty industry?",
        answer: "Yes, Canvas ME was created after years of witnessing the common beauty recruitment issues. We are built from the ground up for beauty professionals. If you work in the beauty space, then Canvas ME is for you."
      },
      {
        question: "2. I’m a barber or esthi, is Canvas ME for me?",
        answer: "Canvas ME is made for all beauty/barber programs—cosmo, esthi, barber, makeup, nails, skin, teaching, massage and all beauty school programs. Canvas ME is a template—its your Canvas—you get to paint it. Canvas ME is also for any non-licensed beauty pro. So if you work in the professional beauty industry at any capacity — Canvas ME is for you."
      },
      {
        question: "3. How much does it cost to create a Canvas ME portfolio?",
        answer: "Creating a Canvas ME portfolio is absolutely free. Our mission is to empower the beauty space to be seen and find their place of belonging so they can live out the career of their dreams."
      },
      {
        question: "4. How is Canvas ME different from a word doc resume?",
        answer: "Word doc resumes are a thing of the past. You are a creative being in a creative industry—how do you showcase that in a word doc resume? Canvas ME captures the essence of who you are as a human. You can upload videos and or imagery of your work right to your canvas me portfolio. You can even sync your Instagram—making your Canvas ME portfolio your portfolio + resume + website all in one. Not only that but once you create a portfolio you live in a community where you can be found."
      },
      {
        question: "5. Can I apply for other opportunities that are not on Canvas ME?",
        answer: "Once you create your Canvas ME portfolio it acts as your Professional website! Its an online resume + online portfolio that becomes a website. You can share your link with anyone anywhere to apply for any opportunity. Not only that, but you can also automatically print a word doc resume right from your Canvas ME portfolio for in person resumes."
      },
      {
        question: "6. I am happy in my current role, do I still need to create a Canvas ME Portfolio?",
        answer: "Canvas ME is about leveling up at every level. So while you may be happy in your current role, Canvas ME will help you land career enhancing opportunities like that opportunity where you assist your favorite mentor OR that brand educator role you’ve always dreamed of. We have thousands of beauty business owners that create Portfolios every day to level up!"
      },
      {
        question: "7. How can I print my resume from my Canvas ME portfolio?",
        answer: "Printing your resume from your Canvas ME portfolio is easy. Once you are logged in, You can view your portfolio and hit the save as PDF button. This will auto generate a printable resume with a custom QR code. The QR code will drive anyone that scans it back to your personal portfolio so that they can watch your videos and see imagery of your work."
      },
      {
        question: "8. How can I market myself through my Canvas ME portfolio?",
        answer: "1) Share your Canvas ME portfolio link with the world—share it in your link in bio or Linktree, share it before, during and a after a career fair. Share it everywhere. <br><br> 2) Print out your resume with QR code. Your QR code drives everyone back to your digital portfolio where they can watch your videos, see your imagery and resume. <br><br> 3) Apply for career opportunities right on Canvas ME with a click of a button. No more uploading resumes and long application forms. Just find the career opportunity that resonates with you and click apply. The job poster will receive your dynamic portfolio versus a static word doc."
      }
    ],
    businessQuestions: [
      {
        question: "1. Can I find talent on Canvas ME?",
        answer: "Yes, we created Canvas ME to empower you to find the right talent for your beauty/barber business. You can browse portfolios based on specific search criteria OR post a career opportunity where beauty pro’s can apply with a click of a button."
      },
      {
        question: "2. What does a Canvas ME Career opportunity look like?",
        answer: "Canvas ME Career opportunities look WAY different than anything you have seen. We are NOT a job board in the traditional sense. When you create a Canvas ME career opportunity we provide the ability for you to upload video of your beauty business. You can capture a small snippet highlighting who you are as a leader or highlight a day in the life working at your beauty business = all things that connect with the new beauty pro."
      },
      {
        question: "3. How does Canvas ME differ from other job sites.",
        answer: "1) Canvas ME is made for the beauty industry. 2) Canvas ME humanizes both beauty professional through digital portfolios AND Beauty businesses through career opportunities that highlight videos and imagery—creating human connections with the RIGHT talent. 3) Canvas ME is working with HUNDREDS of schools in the US revamping the resume curriculum and graduating thousands of professionals looking for their work home. 4) Canvas ME highlights all career enhancing opportunities—looking to host an apprentice or shadow day? Post that to canvas me to create a pool of interested up and coming talent. 5) Canvas ME offers a dashboard of analytics that captures views, applicants and applicant status. 6) Canvas ME offers an applicant tracking system that allows you to categorize your applicants for future recruitment efforts."
      },
      {
        question: "3. How does Canvas ME differ from other job sites.",
        answer: "1) Canvas ME is made for the beauty industry. 2) Canvas ME humanizes both beauty professional through digital portfolios AND Beauty businesses through career opportunities that highlight videos and imagery—creating human connections with the RIGHT talent. 3) Canvas ME is working with HUNDREDS of schools in the US revamping the resume curriculum and graduating thousands of professionals looking for their work home. 4) Canvas ME highlights all career enhancing opportunities—looking to host an apprentice or shadow day? Post that to canvas me to create a pool of interested up and coming talent. 5) Canvas ME offers a dashboard of analytics that captures views, applicants and applicant status. 6) Canvas ME offers an applicant tracking system that allows you to categorize your applicants for future recruitment efforts."
      },
      {
        question: "4. How can I market my Canvas ME opportunity?",
        answer: "While Canvas ME members can search for your opportunity, Your Canvas ME opportunity also serves as a landing page. You can advertise your opportunity anywhere through social pages or via email and drive potential candidates back to your Canvas ME opportunity. This page serves as your career landing page as most of our websites cater to the customer—your canvas me opportunity site talks to the potential employee."
      },
      {
        question: "5. Can I add my Canvas ME opportunity button to my website?",
        answer: "Yes, once you create an opportunity, Canvas ME will automatically provide custom HTML code that you can download and implement on your website. Its like your very own custom Careers page."
      },
      {
        question: "6. Once I post a Career opportunity can I cancel at anytime?",
        answer: "Yes, Canvas ME opportunities can be turned on for a very small monthly fee and we allow you to can cancel at anytime. Most if not all Canvas ME businesses are in the mode of ALWAYS hiring. They keep their opportunity active and build a funnel of interested talent to stay in a proactive mode versus reactive."
      }
    ],
    schoolQuestions: [
      {
        question: "1. What can students do with their Canvas ME portfolio?",
        answer: "1) Students can share their canvas me portfolio links with the world – on their social sites, via email etc. 2) Students will have access to exclusive career enhancing opportunities like mentorships and scholarships even BEFORE they graduate right on canvas me. 3) Students can automatically print their Canvas ME resume that includes QR code 4) Students are in a community of elite professionals where they can be searched and found 5) Students can apply for the Professional Beauty Associations Beacon program—Canvas ME portfolios are a requirement to apply. 6) Students are set up for success by leveraging a digital tool to market themselves throughout their entire beauty career."
      },
      {
        question: "2. What does it mean to become a Canvas ME school?",
        answer: "Canvas ME schools empower their students through Canvas ME portfolios versus word doc resumes. Canvas ME schools know that humanizing their students through portfolios is a much more effective way of helping their students be seen for who they are as humans. Canvas ME portfolios highlight video and imagery to capture the true essence of what the student has to offer."
      },
      {
        question: "2. What does it mean to become a Canvas ME school?",
        answer: "Canvas ME schools empower their students through Canvas ME portfolios versus word doc resumes. Canvas ME schools know that humanizing their students through portfolios is a much more effective way of helping their students be seen for who they are as humans. Canvas ME portfolios highlight video and imagery to capture the true essence of what the student has to offer."
      },
      {
        question: "3. Does Canvas ME have Curriculum for portfolio building?",
        answer: "Yes, through our partnership with Pivot Point, students can access Canvas ME curriculum on demand which highlights classes on portfolio creation, building a bio, creating video content, capturing your work, nailing the interview and more."
      },
      {
        question: "4. How does Canvas ME help with placement?",
        answer: "Canvas ME tracks students and their placement. Once a Canvas ME member lands a role, it is captured on their Canvas ME portfolio. Canvas ME schools are then alerted through their School Dashboard. Canvas ME Portfolio’s can be leveraged for Placement Documentation. Placement leaders can quickly and easily print portfolios to showcase placement."
      },
      {
        question: "5. How does Canvas ME help with our career fairs?",
        answer: "1) All students can automatically print out their Canvas ME resumes that include a custom QR code. 2) Businesses can scan Portfolio QR codes to visit the students Canvas ME portfolio as they visit the school. Once they scan the Canvas ME QR code they can bookmark the student to stay connected beyond the in person career fair. 3) Businesses can create career opportunities on Canvas ME. Once the career fair takes place, businesses can encourage students to apply to their opportunity with a click of a button by leveraging their portfolio. 4) Students/leadership teams can send portfolio links to business owners before, during and after the career fair. This helps for those businesses who don’t make it to the planned career fair. You can now get your students in front of anyone—anywhere, without physically getting in front of them by sharing portfolio links."
      },
      {
        question: "6. How does Canvas ME help with enrollment?",
        answer: "Canvas ME schools leverage student/alumni portfolios to highlight the type of student that attends that school and their journey. Highlighting the digitalization of the resume process also helps showcase a school that embraces and leverages technology to empower their students to market themselves in a revolutionary way—beyond the word doc resume. The “new” professional also demands the use of technology to market themselves."
      },
      {
        question: "7. How can I opt into the Canvas ME School Dashboard?",
        answer: 'Schools can opt into the Canvas ME School Dashboard for a small monthly fee. The dashboard provides a view of your students and their Canvas ME portfolios. The school dashboard highlights metrics like portfolio views, days from graduation to hire, hired status and more. You can request a demo of the Canvas ME School Dashboard by emailing <a href="mailto:hello@canvasme.com" target="_blank" rel="noreferrer noopener">hello@canvasme.com</a>'
      },
    ]
  }
  render() {
    const { selectedQuestionSet, proStudentQuestions, businessQuestions, schoolQuestions } = this.state;
    const { showSelector, customBackground, showHeader, includeSchoolFaqs = true } = this.props;
    const style: any = {};
    if (customBackground) {
      style.background = customBackground;
    }
    return <div className="cm-home-faq cm-flex-center cm-flex-column" id='faq' style={{ background: customBackground }}>
      { showHeader && <h2 className="cm-gutter-bottom-lg">
        Frequently Asked Questions
      </h2> }
      { showSelector && <div className="cm-flex-responsive-row cm-flex-center cm-gutter-bottom-lg">
        <Button
          className={`cm-home-faq__btn-selector ${selectedQuestionSet === 'prostudent' ? 'cm-home-faq__btn-selector--selected' : ''}`}
          onClick={() => this.setState({ selectedQuestionSet: 'prostudent' })}>
          Pros &amp; Students
        </Button>
        <Button
          className={`cm-home-faq__btn-selector ${selectedQuestionSet === 'business' ? 'cm-home-faq__btn-selector--selected' : ''}`}
          onClick={() => this.setState({ selectedQuestionSet: 'business' })}>
          Business Owners
        </Button>
        { includeSchoolFaqs && <Button
          className={`cm-home-faq__btn-selector ${selectedQuestionSet === 'school' ? 'cm-home-faq__btn-selector--selected' : ''}`}
          onClick={() => this.setState({ selectedQuestionSet: 'school' })}>
          Beauty Schools
        </Button> }
      </div> }
      <div style={{ width: "100%" }}>
        {selectedQuestionSet === 'prostudent' && proStudentQuestions.map(psq => (
          <FaqExpander question={psq.question} answer={psq.answer} />
        ))}
        {selectedQuestionSet === 'business' && businessQuestions.map(psq => (
          <FaqExpander question={psq.question} answer={psq.answer} />
        ))}
        {selectedQuestionSet === 'school' && schoolQuestions.map(psq => (
          <FaqExpander question={psq.question} answer={psq.answer} />
        ))}
      </div>
    </div>;
  }
}
