import React from 'react';
import { Button } from 'antd';
import "./home_video_preview.less";

import pivotPoint from "../../../assets/images/home/pivot-point-logo.png";

interface Props {
  headline: string;
  ctaText: string;
  imageSource: any;
  imageAlt: string;
  showLogos?: boolean;
  setCurPlayUrl: any;
  videoUrl: string;
  ctaAction: any;
}

export class HomeVideoPreview extends React.Component<Props, {}> {
  render() {
    const { imageSource, imageAlt, headline, ctaText, showLogos, setCurPlayUrl, videoUrl, ctaAction } = this.props;
    return <div className="cm-home-video-preview cm-flex-responsive-row">
      <img className="cm-home-video-preview__featured" src={imageSource} alt={imageAlt} />
      <div className="cm-home-video-preview__content">
        <h2>{headline}</h2>
        <div className="cm-flex-responsive-row">
          <Button className="btn-primary-light" onClick={() => setCurPlayUrl(videoUrl)}>Watch video</Button>
          <Button className="btn-secondary" onClick={ctaAction}>{ctaText}</Button>
        </div>
        { showLogos && <div className="cm-flex-row">
          <img className="cm-home-video-preview__logo" src={pivotPoint} alt="Pivot Point" />
        </div> }
      </div>
    </div>;
  }
}
