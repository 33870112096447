import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { Button } from "antd";
import StudentProBrands from "./studentpro_brands";
import _ from "lodash";
import { global_router } from "../../../routers";
import { router_get_started } from "../../../utils/enum";
import VimeoPlayer from "../../../components/component_vimeo_player";
import "./studentpro.less";
import "./sc_no_show_landing.less";
import { HomeTestimonials } from "../home/home_testimonials";
import browsePorts from "../../../assets/images/sc_landing/browse-portfolios.png";
import attractTalent from "../../../assets/images/sc_landing/attract-talent.png";
import trackApplicants from "../../../assets/images/sc_landing/track-applicants.png";
import WellaBlack from "../../../assets/images/landingpage/Wella_Black.png";
import WellaWhite from "../../../assets/images/landingpage/Wella_White.png";
import "./wella_landing.less"

const WellaLanding: React.FC = () => {
  const [email, setEmail] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
 
  const source = urlParams.get('source') ? urlParams.get('source') : "wella";
  const rep = urlParams.get('rep') ? urlParams.get('rep') : null;

  const initSignup = () => {
    if (!email) {
      global_router.history.push(`${router_get_started}?source=${source}&rep=${rep}`);
    }

    global_router.history.push(
      `${router_get_started}?prefilled=${encodeURIComponent(
        email
      )}&source=${source}&rep=${rep}`
    );
  };

  return (
    <div className="cm-sc-landing cm-wella-landing">
      <div className="wella-logo">
        <p className="wella-x">x</p><img src={WellaWhite} className="wella-white" alt="Wella"/>
      </div>
      <div className="cm-sp-landing">
        <div className="cm-sp-headline-root cm-flex-responsive-row">
          <div className="cm-sp-hero-content">
            <div className="txt-container">
              <p className="headliner">
                Level Up Your Career And
                Become A Brand Educator.
              </p>
              <p className="details">
                Create your free portfolio on Canvas ME and get discovered by
                brands like Matrix, Pulp Riot, & Redken
              </p>
            </div>
            <form
              onSubmit={initSignup}
              className="cm-hero-cta-form-wella cm-flex-responsive-row cm-full-width cm-flex-v-center top-form"
            >
              <TextField
                key="email"
                label="Email"
                variant="filled"
                className="cm-txt-field-wella-form material-input input cm-flex-1"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button htmlType="submit" className="cm-btn-wella-form btn-primary btn-sign-up">
                <span className="cm-btn-txt-wella-form">Join in less than 3 minutes</span>
              </Button>
            </form>
          </div>
          <div className="cm-sp-hero-img-wrapper-wella video-position">
            <VimeoPlayer vidSrc="https://player.vimeo.com/video/837690496?h=f02ca8815b&color=0064b4&title=0&byline=0&portrait=0" />
          </div>
        </div>
      </div>
      {/* <div className="brands">
        <StudentProBrands
          headline="Trusted by the industry's leading brands, schools, and salons:"
          isSalonCentricStopInterview={true}
        />
      </div> */}
      <HomeTestimonials isWellaLP={true} isSalonCentricStopInterview={true} />
      <div className="cta">
        <p className="cta-header">
          Join the fastest-growing beauty hiring platform in the world
        </p>
        <form
          onSubmit={initSignup}
          className="cm-flex-responsive-row cm-full-width cm-flex-v-center"
        >
          <TextField
            key="email"
            label="Email"
            variant="filled"
            className="material-input input cm-flex-1"
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button htmlType="submit" className="btn-primary btn-cta">
            Post Job Listing
          </Button>
        </form>
      </div>
      <div className="section">
        <img src={attractTalent} alt="Attract Talent" />
        <div className="txt">
          <h1>Create Job Posts & Attract Talent</h1>
          <p>
            You're tired of wasting time and energy posting job ads that don't
            resonate with the right people. With Canvas ME, you create engaging
            job pages with videos and images that humanize your brand and
            culture. Each job post you make is sharable and automatically
            included in weekly alerts to our members. So, you're not just
            posting a job - you're building your team of passionate
            professionals.
          </p>
        </div>
      </div>
      <div className="section change-order">
        <div className="txt item">
          <h1>Browse Portfolios & Filter Pros</h1>
          <p>
            You need a seamless way to identify top talent from a pool of
            applicants. With our platform, you can browse Canvas ME portfolios
            of professionals, getting a real sense of their skill and style. You
            can filter candidates based on KPIs, brands they've worked with, and
            years of experience. You spend time evaluating the right people, not
            sifting through endless resumes.
          </p>
          <form
            onSubmit={initSignup}
            className="cm-flex-responsive-row cm-full-width cm-flex-v-center three-quarter-width"
          >
            <TextField
              key="email"
              label="Email"
              variant="filled"
              className="material-input input cm-flex-1 input-bg"
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button htmlType="submit" className="btn-primary btn-cta">
              Post Job Listing
            </Button>
          </form>
        </div>
        <img className="item-2" src={browsePorts} alt="Browse Portfolios"/>
      </div>
      <div className="section">
        <img src={trackApplicants} alt="Track Applicants"/>
        <div className="txt">
          <h1>
            Track Applicants & Connect
            <br /> Via Text Chat and Videos
          </h1>
          <p>
            Never drop the ball again: Canvas ME gives you a hassle-free way to
            manage your hiring pipeline. You can set statuses on applicants and
            effortlessly track your hiring progress. Connect directly with
            candidates with built-in chat and video messaging. Make a great
            first impression, and take advantage of every oppportunity to engage
            with top talent.
          </p>
        </div>
      </div>
      <div className="cta">
        <p className="cta-header">
          Attract and hire the best candidates all in one place
        </p>
        <form
          onSubmit={initSignup}
          className="cm-flex-responsive-row cm-full-width cm-flex-v-center"
        >
          <TextField
            key="email"
            label="Email"
            variant="filled"
            className="material-input input cm-flex-1"
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button htmlType="submit" className="btn-primary btn-cta">
            Post Job Listing
          </Button>
        </form>
      </div>
    </div>
  );
};

export default WellaLanding;
