import React from 'react';
import { HomeVideoPreview } from './home_video_preview';

import profileSchools from "../../../assets/images/home/profile-school.png";
import iconEnrollment from "../../../assets/images/home/icons/icons_enrollment.svg";
import iconStudents from "../../../assets/images/home/icons/icons_students.svg";
import iconGraduation from "../../../assets/images/home/icons/icons_graduation.svg";
import iconApproved from "../../../assets/images/home/icons/icons_approved.svg";
import iconFeature from "../../../assets/images/home/icons/icons_feature.svg";
import iconSocial from "../../../assets/images/home/icons/icons_social.svg";
import iconOwl from "../../../assets/images/home/icons/icons_owl.svg";
import iconCourse from "../../../assets/images/home/icons/icons_course.svg";
import { VidProps } from './home_business';

export class HomeSchools extends React.Component<VidProps, {}> {
  render() {
    const { setCurPlayUrl, videoUrl, ctaAction } = this.props;
    return <div className="cm-home-persona">
      <HomeVideoPreview
        imageSource={profileSchools}
        showLogos={ true }
        setCurPlayUrl={setCurPlayUrl}
        videoUrl={videoUrl}
        ctaAction={ctaAction}
        imageAlt='Beauty schools'
        headline='For Beauty Schools To Market &amp; Track Their Students and Alumni'
        ctaText='Become a Canvas ME School' />
      <div className="cm-home-info-section">
        <div className="cm-flex-column">
          <div>
            <h3>Admissions</h3>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconEnrollment} alt="enrollment" />
              <p>Attract new enrollment by showcasing and sharing current students work on Canvas ME portfolios.</p>
            </div>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconStudents} alt="eye" />
              <p>Leverage the professional portfolio as a benefit to joining your school.</p>
            </div>
          </div>
          <div>
            <h3>Placement</h3>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconGraduation} alt="resume" />
              <p>Provide opportunities like mentorships/scholorships so your students can get some experience before they graduate.</p>
            </div>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconApproved} alt="QR code" />
              <p>Leverage Canvas ME portfolios for Placement Documentation.</p>
            </div>
          </div>
        </div>
        <div className="cm-flex-column">
          <div>
            <h3>Marketing</h3>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconFeature} alt="career" />
              <p>Feature videos and imagery from student portfolios to humanize your school.</p>
            </div>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconSocial} alt="alert" />
              <p>Help students be seen on your social channels by sharing their Canvas ME link in bio.</p>
            </div>
          </div>
          <div>
            <h3>Curriculum</h3>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconOwl} alt="owl" />
              <p>Canvas ME offers portfolio/resume building, interview coaching, mindset and personal development courses.</p>
            </div>
            <div className="cm-home__info-block cm-flex-row cm-flex-v-center">
              <img className="cm-home__icon" src={iconCourse} alt="course" />
              <p>Courses are available through the Pivot Point catalogue and Canvas ME Learning Management system.</p>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}
